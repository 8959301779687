import React, { useState, useEffect } from "react";
// import { Pagination, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import moment from "moment";
import { toast } from "react-toastify";
import DefaultUserImg from "../../Assets/Images/icons/Icon awesome-user-circle.png";
// import plusIcon from "../../Assets/Images/Icon feather-plus.png";
// import Modal from "react-modal";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const WellnessCoachDetails = () => {
  const navigate = useNavigate();
  const [Details, setDetails] = useState([]);
  const [LoadingDetails, setLoadingDetails] = useState(false);
  const UserData = JSON.parse(localStorage.getItem("superadmin"));
  const param = useParams();

  // const customStyles = {
  //   content: {
  //     top: "50%",
  //     left: "50%",
  //     right: "auto",
  //     bottom: "auto",
  //     marginRight: "-50%",
  //     transform: "translate(-50%, -50%)",
  //     background: "#FFF",
  //     borderRadius: "20px",
  //     paddingTop: "20px",
  //     width: "90%",
  //     maxWidth: "500px",
  //     boxShadow: "0px 0px 24px -10px #bdbdbd",
  //   },
  // };

  // const [modalIsOpen, setIsOpen] = React.useState(false);

  // function openModal() {
  //   setIsOpen(true);
  // }

  // function afterOpenModal() {
  // }

  // function closeModal() {
  //   setIsOpen(false);
  // }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const GetDetails = () => {
    setLoadingDetails(true);
    // console.log("ID", param?.id);
    try {
      API.Common.WellnesscoachDetails({
        data: { _id: param?.id },
      }).then((response) => {
        if (response?.data?.status === 1) {
          setDetails(response?.data?.data);
          setLoadingDetails(false);
        }
      });
    } catch (error) {
      console.log(error);
      setLoadingDetails(false);
    }
  };

  //useeffect api call
  useEffect(() => {
    GetDetails();
    MyorgListdata();
  }, []);
  const [MyorgList, setMyorgList] = useState([]);
  const [LoadingMyorgList, setLoadingMyorgList] = useState(false);

  //organization list api
  const MyorgListdata = () => {
    setLoadingMyorgList(true);
    try {
      API.Common.MyOrgListforwellnesscoach({}).then((response) => {
        if (response.data.status === 1) {
          // console.log("myorg", response?.data?.data);
          setMyorgList(response?.data?.data_all);
        }
        setLoadingMyorgList(false);
      });
    } catch (error) {
      setLoadingMyorgList(false);
      console.log(error);
    }
  };

  //approve profle
  const GetApproveUser = (e) => {
    // setLoadingDetails(true)
    // console.log(param?.id);
    try {
      API.Common.IsApproveall({
        data: {
          _id: param?.id,
          is_authorized: 1,
          role: "Wellnessclient",
        },
      }).then((response) => {
        if (response?.data?.status === 1) {
          navigate(`../${AdminRoute?.Common?.WellnessCoach}`);
        }
      });
    } catch (error) {
      console.log(error);
      // setLoadingDetails(false)
    }
  };
  const GetRejectUser = (e) => {
    // setLoadingDetails(true)
    // console.log(param?.id);
    try {
      API.Common.IsApproveall({
        data: {
          _id: param?.id,
          is_authorized: 0,
          role: "Wellnessclient",
        },
      }).then((response) => {
        if (response?.data?.status === 1) {
          navigate(`../${AdminRoute?.Common?.WellnessCoach}`);
        }
      });
    } catch (error) {
      console.log(error);
      // setLoadingDetails(false);
    }
  };

  //assign wellnesscoach to organization api

  const GetAssignorg = (e, val) => {
    e.preventDefault();
    //  console.log(param?.id);
    //  console.log(val?._id);
    //  console.log(val?.org_name);
    setLoadingDetails(true);
    try {
      API.Common.WellnesscoachAssign({
        data: {
          coach_id: param?.id,
          org_id: val?._id,
          created_by: UserData?.data?._id,
          Companyname: val?.org_name,
        },
      }).then((response) => {
        //    console.log(response?.data?.status);
        if (response?.data?.status === 1) {
          navigate(`../${AdminRoute?.Common?.WellnessCoach}`);
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
        setLoadingDetails(false);
      });
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.message);
      setLoadingDetails(false);
    }
  };
  //jsx
  return (
    <div className="d-flex h-dashboard playground">
      <Sidebar />

      <div className="toggleIcon">

        <div className="container-fluid">
          {LoadingDetails ? (
            <figure>
              <div className="dot white"></div>
              <div className="dot"></div>
              {/* <div className="dot"></div> */}
              {/* <div className="dot"></div>
                                                            <div className="dot"></div> */}
            </figure>
          ) : (
            <div className="row list dashboard therapist">
              <div className="nac-wrap">
                <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                  <div className="w-100 h-head">
                    <h2 className="heading p-0">
                      <i
                        className="fa fa-chevron-left cursor_pointer pe-2"
                        aria-hidden="true"
                        onClick={() => navigate(-1)}
                      ></i>{" "}
                      Wellness Coach Details
                    </h2>
                  </div>
                  <Header />
                </nav>
              </div>

              {/* <span className="my-checklist-modal">
                <Modal
                  isOpen={modalIsOpen}
                  onAfterOpen={afterOpenModal}
                  onRequestClose={closeModal}
                  style={customStyles}
                >
                  <button onClick={closeModal} className="close-btn ">
                    <img src={plusIcon} />{" "}
                  </button>
                  <div>

                    <p className="text-danger text-center p-4 fs-5">This action cannot be reverted. Do you want to proceed?</p>

                  </div>
                  <div className="row">
                    <div className="col-6">
                      <button
                        type="submit"
                        className="button_common w-100 mt-2 mb-0 text-center"
                        role="button"
                        onClick={(e) => GetRejectUser()}
                      >
                        Confirm
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        type="submit"
                        className="button_common w-100 mt-2 mb-0 text-center button_common-border bg-white"
                        role="button"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>

                </Modal>
              </span> */}


              <div className="col-md-12">
                {/* <h4 className='secheading mb-2'>Request From Wellness Coach</h4>
                          <p className="mb-3">Here is a detail list of all Requests.</p> */}
                <div className="row">

                  <div className="my-organization">
                    <div className="row gx-3">
                      {Details?.is_authorized === 0 ? (
                        <div className="col-12 col-md-4">
                          <div className="approval-employee mb-3">
                            <div className="employee-box">
                              <div className="employee-box-inner employee-box-inner-first p-3 d-block">
                                <div className="text-div-sessions px-0 text-start">
                                  <div className="slot-div-name text-dark">
                                    {Details?.fullname}
                                  </div>
                                  <div className="slot-div-date small-text">
                                    {Details?.role}
                                  </div>
                                </div>
                                <div className="clock text-end small-text">
                                  {moment(Details?.createdAt).format(
                                    "DD/MM/YYYY"
                                  )}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-6">
                                  <button
                                    type="submit"
                                    className="create-new_notes button_common notes-btn transparent-btn btn btn-primary bg-white mb-0 mt-2"
                                    // onClick={openModal}
                                    onClick={(e) => GetRejectUser()}
                                  >
                                    <a className="">Reject</a>
                                  </button>
                                </div>
                                <div className="col-6">
                                  <button
                                    type="submit"
                                    className="create-new_notes notes-btn btn btn-primary mb-0 mt-2 button_common approve-btn"
                                    onClick={(e) => GetApproveUser()}
                                  >
                                    <a className="">Approve</a>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-12 b-0">
                          <div className="card-body payment-card-body p-0 profile-wrap">
                            <div className="row">
                              <div className="col-2">
                                <div className="row h-100">
                                  <div className="col-12 d-flex align-items-center">
                                    <div className="profile-image-box d-flex justify-content-center align-items-center mx-auto">
                                      <img
                                        src={
                                          Details?.image
                                            ? Details?.image
                                            : DefaultUserImg
                                        }
                                      ></img>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-10">
                                <div className="row pt-3 pe-3">
                                  <div className="col-12">
                                    <h6 className="mb-2">Personal Details</h6>
                                    <hr />
                                  </div>
                                  <div className="col-12">
                                    <form>
                                      <div className="row">
                                        <div className="col-12">
                                          <div className="row">
                                            <div className="col-6">
                                              <div className="mb-3">
                                                <label
                                                  htmlFor="Full Name"
                                                  className="form-label"
                                                >
                                                  Full Name
                                                </label>
                                                <input
                                                  type="text"
                                                  readOnly
                                                  className="form-control"
                                                  placeholder="Full name"
                                                  aria-describedby="Full Name"
                                                  defaultValue={
                                                    Details?.fullname
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="col-6">
                                              <div className="mb-3">
                                                <label
                                                  htmlFor="Email"
                                                  className="form-label"
                                                >
                                                  Email
                                                </label>
                                                <input
                                                  type="email"
                                                  className="form-control"
                                                  readOnly
                                                  placeholder="Email"
                                                  aria-describedby="Email"
                                                  defaultValue={Details?.email}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-6">
                                              <div className="mb-3">
                                                <label
                                                  htmlFor="Age"
                                                  className="form-label"
                                                >
                                                  Date Of Birth
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  readOnly
                                                  placeholder="Date of Birth"
                                                  value={moment(
                                                    Details?.dob
                                                  ).format("DD/MM/YYYY")}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-4">
                                              <div className="mb-3">
                                                <label
                                                  htmlFor="Contact No"
                                                  className="form-label"
                                                >
                                                  Contact No.
                                                </label>
                                                <input
                                                  type="number"
                                                  className="form-control"
                                                  readOnly
                                                  placeholder="Contact No."
                                                  aria-describedby="Contact No"
                                                  defaultValue={Details?.phone}
                                                />
                                              </div>
                                            </div>
                                            {/* <div className="col-4">
                                                                                  <div className="mb-3">
                                                                                      <label htmlFor="Assign Employee" className="form-label">
                                                                                          Assign Employee
                                                                                      </label>
                                                                                      <input
                                                                                          type="text"
                                                                                          className="form-control"
                                                                                          readOnly
                                                                                          placeholder="Assign Employee"
                                                                                          aria-describedby="Assign Employee"
                                                                                          defaultValue={Details?.phone}
                                                                                      />
                                                                                  </div>
                                                                              </div> */}
                                            {/* <div className="col-4">
                                                                                  <div className="mb-3">
                                                                                      <label htmlFor="Assign Organization" className="form-label">
                                                                                          Assign Organization
                                                                                      </label>
                                                                                      <input
                                                                                          type="text"
                                                                                          className="form-control"
                                                                                          readOnly
                                                                                          placeholder="Assign Organization"
                                                                                          aria-describedby="Assign Organization"
                                                                                          defaultValue={Details?.phone}
                                                                                      />
                                                                                  </div>
                                                                              </div> */}
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {/*----------------- Newly Deed Slider Code End here-----------*/}
                    </div>
                  </div>
                </div>
                <h4 className="secheading mb-2 mt-3">Organization List</h4>
                <p className="mb-3">
                  Here is a detail list of all your Organization.
                </p>
                <div className="row mb-3">
                  <div className="my-organization">
                    <div className="row gx-3">
                      {/*----------------- Newly addeed Slider Code start here-----------*/}

                      <div className="col-12 px-0">
                        <Carousel responsive={responsive}>
                          {LoadingMyorgList ? (
                            <figure>
                              <div className="dot white"></div>
                              <div className="dot"></div>
                              {/* <div className="dot"></div> */}
                              {/* <div className="dot"></div>
                                                          <div className="dot"></div> */}
                            </figure>
                          ) : MyorgList.length === 0 ? (
                            <div
                              className="alert alert-warning digital-notes-alert"
                              role="alert"
                            >
                              Data Not Found
                            </div>
                          ) : (
                            MyorgList &&
                            MyorgList.map((val) => (
                              <div className="row">
                                <div className="approval-employee mb-3 px-0">
                                  <div className="employee-box mx-3">
                                    <div className="employee-box-inner employee-box-inner-first p-3 d-block">
                                      <div className="text-div-sessions px-0 text-start">
                                        <div className="slot-div-name text-dark">
                                          {val?.org_name}
                                        </div>
                                        {/* <div className="slot-div-name text-dark">{val?._id}</div> */}
                                        <div className="slot-div-date small-text">
                                          {val?.role}
                                        </div>
                                      </div>
                                      <div className="clock text-end small-text">
                                        {moment(val?.createdAt).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </div>
                                    </div>
                                    <div className="employee-box-inner mt-1 row">
                                      <div className="col-6">
                                        <p className="small-text">Sector</p>
                                        <p className="text-dark fw-bold">
                                          {val?.Sector}
                                        </p>
                                      </div>
                                      <div className="col-6">
                                        <p className="small-text">Location</p>
                                        <p className="text-dark fw-bold locations">
                                          {val?.Companyaddress}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row justify-content-end">
                                      <div className="col-6">
                                        <button
                                          type="submit"
                                          className="create-new_notes notes-btn btn btn-primary mb-0 mt-2 button_common approve-btn"
                                          onClick={(e) => GetAssignorg(e, val)}
                                        >
                                          <a className="" href="#">
                                            Assign
                                          </a>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </Carousel>
                      </div>

                      {/*----------------- Newly Deed Slider Code End here-----------*/}

                      {/*----------------- Old Withoud Slider Code start here-----------*/}
                      {/* {LoadingMyorgList ? (
                                          <figure>
                                              <div className="dot white"></div>
                                              <div className="dot"></div>
                                              {/* <div className="dot"></div> */}
                      {/* <div className="dot"></div>
                                          //                 <div className="dot"></div> */}
                      {/* // </figure> */}
                      {/* ) : MyorgList.length === 0 ? (
                                          <div
                                              className="alert alert-warning digital-notes-alert"
                                              role="alert"
                                          >
                                              Data Not Found
                                          </div>
                                      ) : (
                                          MyorgList &&
                                          MyorgList.map((val) => (
                                              <div className="col-12 col-md-4">
                                                  <div className="approval-employee mb-3">
                                                      <div className="employee-box">
                                                          <div className='employee-box-inner employee-box-inner-first p-3 d-block'>
                                                              <div className='text-div-sessions px-0 text-start'>
                                                                  <div className="slot-div-name text-dark">{val?.Companyname}</div>
                                                                  <div className="slot-div-date small-text">{val?.role}</div>
                                                              </div>
                                                              <div className="clock text-end small-text">
                                                                  {moment(val?.createdAt).format("LL")}
                                                              </div>
                                                          </div>
                                                          <div className='employee-box-inner mt-1 row'>
                                                              <div className="col-6">
                                                                  <p className='small-text'>Sector</p>
                                                                  <p className='text-dark fw-bold'>{val?.Sector}</p>
                                                              </div>
                                                              <div className='col-6'>
                                                                  <p className='small-text'>Location</p>
                                                                  <p className='text-dark fw-bold'>{val?.Companyaddress}</p>
                                                              </div>
                                                          </div>
                                                          <div className='row justify-content-end'>
                                                              <div className='col-6'>
                                                                  <button
                                                                      type="submit"
                                                                      className="create-new_notes notes-btn btn btn-primary mb-0 mt-2 button_common approve-btn"
                                                                      onClick={(e) => GetAssignorg(val)}
                                                                  >
                                                                      <a className="" href="#">Assign</a></button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          )))} */}

                      {/*----------------- Old Withoud Slider Code End here-----------*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Footer />
        </div>
      </div>
    </div>
  );
};
export default WellnessCoachDetails;
