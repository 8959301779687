const RouteName = {
  Auth: {
    Login: "/login",
  },
  Common: {
    Dashboard: "/",
    Profile: "/Profile",
    Settings: "/Settings",
    PageNotFound: "*",
    Profileedit: "/Profileedit",
    // ----------------------Super Admin Routes----------------------
    Organizations: "/Organizations",
    OrganizationDetails:"/OrganizationDetails",
    EmployeeDetailsList:"/EmployeeDetailsList",
    Clients: "/Clients",
    ClientsDetails: "/ClientsDetails",
    Therapist: "/Therapist",
    TherapistDetails: "/TherapistDetails",
    Employees: "/Employees",
    EmployeesDetails: "/EmployeesDetails",
    WellnessCoach: "/WellnessCoach",
    WellnessCoachDetails: "/WellnessCoachDetails",
    ResearchTeam: "/ResearchTeam",
    ResearchTeamDetails: "/ResearchTeamDetails",
    PlaygroundList: "/PlaygroundList",
    CreateContent: "/CreateContent",
    AddTherapist:"/AddTherapist",
    Addwellnesscoach:"/Addwellnesscoach",
    Addorganization:"/Addorganization"
  },
};

export default RouteName;
