// App.js
import React, { useEffect, useState, useContext } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import API from "../../Api/Api";
import moment from "moment";

function Dashboard() {
  const [Count, setCount] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [list, setlist] = useState([]);
  const [Loadinglist, setLoadinglist] = useState(false);
  useEffect(() => {
    getCountList("");
    getPlaygroundList("health");
  }, []);

  const getCountList = () => {
    setLoading(true);
    try {
      API.Common.DashboardCountapi({}).then((response) => {
        // console.log(response.data);
        setCount(response?.data);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // playground mental health list api

  const getPlaygroundList = () => {
    setLoadinglist(true);
    try {
      API.Common.Playgroundlist({
        key: "health",
      }).then((response) => {
        // console.log(response.data);
        setlist(response?.data?.data_all);
        setLoadinglist(false);
      });
    } catch (error) {
      console.log(error);
      setLoadinglist(false);
    }
  };

  return (
    <div className="d-flex h-dashboard">
      <Sidebar />
      {/* <figure>
        <div className="dot white"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </figure> */}
      :
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row list gx-3">
            <div className="">
              <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                <h2 className="heading">
                  Welcome
                  <span className="secheading">
                    {/* {UserData?.userdata?.name}! */}
                  </span>
                </h2>
                <Header />
              </nav>
            </div>{" "}
            {/* Header*/}
            <div className="col-lg-12 col-md-12 mb-2 dashborad-wrap">
              <div className="row">
                <div className="col-lg-7 mb-2 col-md-12 super-admin-dashboard">
                  <div className="row">
                    <div className="col-12 mb-3">
                      <div className="dashboard-inner p-3">
                        <div className="row">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-md-12 col-lg-12 d-flex justify-content-between align-items-top">
                                <div>
                                  <h6 className="mb-0">
                                    <span className="fw-bold">Revenue</span>{" "}
                                    Analytics
                                  </h6>
                                  <p className="mb-3 text-muted">
                                    Here is an overview on how is your
                                    organization performing.
                                  </p>
                                </div>
                                <p className="mt-0">As of Today</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="row">
                              <div className="col-md-12 col-lg-5">
                                <div className="dashboard-graph">
                                  Graph will come here
                                </div>
                              </div>
                              <div className="col-md-12 col-lg-7">
                                <div className="row">
                                  <h6>
                                    Total{" "}
                                    <span className="fw-bold">Revenue</span>
                                  </h6>
                                  <h2 className="d-color">
                                    Rs.{" "}
                                    <span className="fw-bold">1,23,600/-</span>
                                  </h2>

                                  <div className="col-12 col-md-6">
                                    <p className="fw-bold">Paid Features</p>
                                    <ul className="mb-2">
                                      <li>Ai Therapist Rs. XXX/-</li>
                                      <li>Group Rs. XXX/-</li>
                                      <li>Digital Notes Rs. XXX/-</li>
                                      <li>Playground Rs. XXX/-</li>
                                    </ul>
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <p className="fw-bold">Subscription Plan</p>
                                    <ul className="mb-2">
                                      <li>Pro Plan Rs. XXX/-</li>
                                      <li>Basic Plan Rs. XXX/-</li>
                                    </ul>
                                  </div>

                                  <div className="col-12 text-end">
                                    <button className="details bg-orange px-3 py-2 rounded-pill border-0 ft-size-12">
                                      View Details
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="dashboard-inner p-3 bg-white border">
                        <div className="row">
                          <div className="col-12">
                            <div className="row">
                              <div className="col-md-12 col-lg-12 d-flex justify-content-between align-items-top">
                                <div>
                                  <h6 className="mb-0">
                                    <span className="fw-bold">
                                      Total Members
                                    </span>{" "}
                                    Overview
                                  </h6>
                                  <p className="mb-3 text-muted">
                                    Here is an overview of the member break up.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="row">
                              <div className="col-md-12 col-lg-4">
                                <div className="users-box h-100 p-3">
                                  <h1 className="d-color">
                                    {Count?.connectUsers + Count?.cmsOrg}
                                  </h1>
                                  <p>
                                    <span className="fw-bold">RYTLife</span>{" "}
                                    Users
                                  </p>
                                  <p className="text-muted ft-size-12 text-end mt-3">
                                    As of Today
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-12 col-lg-8">
                                <div className="row">
                                  <div className="col-12 col-md-6">
                                    <h6 className="mb-0">
                                      <span className="fw-bold">Connect</span>{" "}
                                      Users
                                    </h6>
                                    <h2 className="d-color mt-2 mb-1">
                                      {Count?.connectUsers}
                                    </h2>
                                    <hr className="my-2" />
                                    <div className="row">
                                      <div className="col-6">
                                        <h4 className="text-muted mb-0">
                                          {Count?.Client}
                                        </h4>
                                        <p className="mt-0 ft-size-12">
                                          Clients
                                        </p>
                                      </div>
                                      <div className="col-6">
                                        <h4 className="text-muted mb-0">
                                          {Count?.therpist}
                                        </h4>
                                        <p className="mt-0 ft-size-12">
                                          Therapist
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <h6 className="mb-0">
                                      <span className="fw-bold">CMS</span>{" "}
                                      Organizations
                                    </h6>
                                    <h2 className="d-color mt-2 mb-1">
                                      {Count?.cmsOrg}
                                    </h2>
                                    <hr className="my-2" />
                                    <div className="row">
                                      <div className="col-6">
                                        <h4 className="text-muted mb-0">
                                          {Count?.Cmsemployee}
                                        </h4>
                                        <p className="mt-0 ft-size-12">
                                          Employees
                                        </p>
                                      </div>
                                      <div className="col-6">
                                        <h4 className="text-muted mb-0">
                                          {Count?.Wellnessclient}
                                        </h4>
                                        <p className="mt-0 ft-size-12">
                                          Wellness Coaches
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* dashboard 2 sections End*/}
                <div className="col-lg-5 mb-2 col-md-12">
                  <div className="dashboard-inner p-3 h-100">
                    <div className="row">
                      <div className="dashboard-text-div">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-md-12 col-lg-12">
                              <div>
                                <h6 className="mb-0">
                                  <span className="fw-bold">Activity</span>{" "}
                                  Board
                                </h6>
                                <p className="mb-3 text-muted">
                                  Here is an overview on how is your
                                  organization performing.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="row activity-box-v-height">
                            <div className="activity-wrap">
                              {Loadinglist ? (
                                <figure>
                                  <div className="dot white"></div>
                                  <div className="dot"></div>
                                  {/* <div className="dot"></div> */}
                                  {/* <div className="dot"></div>
                                                            <div className="dot"></div> */}
                                </figure>
                              ) : list.length === 0 ? (
                                <div
                                  className="alert alert-warning digital-notes-alert"
                                  role="alert"
                                >
                                  Data Not Found
                                </div>
                              ) : (
                                list &&
                                list.map((val, index) => (
                                  <div className="col-12 mb-3">
                                    <div className="activity-box bg-white p-3">
                                      <h6 className="fw-bold mb-1">
                                        {val?.header}
                                      </h6>
                                      <p className="mb-0">{val?.created_by}</p>
                                      <div className="d-flex justify-content-between">
                                        <p className="text-muted mb-0">
                                          {val?.type}
                                        </p>
                                        <p className="text-muted mb-0">
                                          {moment(val?.createdAt).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
