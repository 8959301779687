import React, { useState, useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { Pagination } from "react-bootstrap";
import plusIcon from "../../Assets/Images/Icon feather-plus.png";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // height: "500px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#FFF",
    borderRadius: "20px",
    paddingTop: "20px",
    width: "90%",
    maxWidth: "500px",
    boxShadow: "0px 0px 24px -10px #bdbdbd",
  },
};

const EmployeeDetailsList = () => {
  const navigate = useNavigate();
  const [Cmsemployee, setCmsemployee] = useState([]);
  const [Cmsemployeeinactive, setCmsemployeeinactive] = useState([]);
  const [LoadingCmsemployee, setLoadingCmsemployee] = useState(false);
  const [tab, setTab] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const param = useParams();

  const [currentPageinactive, setCurrentPageinactive] = useState(1);
  const [itemsPerPageinactive] = useState(50);
  const indexOfLastIteminactive = currentPageinactive * itemsPerPageinactive;
  const indexOfFirstIteminactive = indexOfLastItem - itemsPerPageinactive;

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    getEmployeeList();
    getEmployeeListinactive();
  }, []);
  const getEmployeeList = (Key) => {
    // console.log(param?.Cname);
    setLoadingCmsemployee(true);
    try {
      API.Common.getOrgEmpDetails({
        key: Key,
        name: param?.Cname,
      }).then((response) => {
        // console.log(response.data);
        setCmsemployee(response?.data?.data);
        setLoadingCmsemployee(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingCmsemployee(false);
    }
  };

  const currentItems = Cmsemployee.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(Cmsemployee.length / itemsPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const currentItemsinactive = Cmsemployeeinactive?.slice(
    indexOfFirstIteminactive,
    indexOfLastIteminactive
  );
  const totalPagesinactive = Math.ceil(
    Cmsemployeeinactive?.length / itemsPerPageinactive
  );
  const handlePageChangeinactive = (page) => {
    setCurrentPageinactive(page);
  };

  const handleSelect = (Key) => {
    // console.log(Key);

    if (Key === "active") {
      getEmployeeList(1);
      // console.log("active", Key);
      setTab(Key);
    }
    if (Key === "inactive") {
      getEmployeeListinactive();
      // console.log("inactive", Key);
      setTab(Key);
    }
  };

  const getEmployeeListinactive = () => {
    // console.log(param?.Cname);
    setLoadingCmsemployee(true);
    try {
      API.Common.EmployeeListbycsvorgviewdetailsemployee({
        name: param?.Cname,
      }).then((response) => {
        // console.log(response.data);
        setCmsemployeeinactive(response?.data?.data_all);
        setLoadingCmsemployee(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingCmsemployee(false);
    }
  };

  //jsx
  return (
    <div className="d-flex h-dashboard">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row list">
            <div className="">
              <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                <div className="w-100 h-head">
                  <h2 className="heading pt-0 mt-1 mb-1">
                    <i
                      className="fa fa-chevron-left cursor_pointer pe-2"
                      aria-hidden="true"
                      onClick={() => navigate(-1)}
                    ></i>{" "}
                    Organization Employee Detail List
                  </h2>
                </div>
                <Header />
              </nav>
            </div>

            <span className="my-checklist-modal">
              <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
              >
                <button onClick={closeModal} className="close-btn ">
                  <img src={plusIcon} />{" "}
                </button>
                <div>
                  <input
                    type="text"
                    placeholder="Bulk Uploads"
                    className="my-task-add"
                    disabled
                  />

                  <input
                    type="File"
                    placeholder="Attach File"
                    className="my-3 mt-4 border rounded-end w-100"
                    name="File"
                    // onChange={(e) => handleChange(e)}
                  />
                  {/* <p className="text-danger">{formErrors?.Title}</p> */}

                  <p className="mb-4 mt-2">
                    <a href="#" className="text-dark text-decoration-underline">
                      Download a Blank Sample Template CSV File HTML for Bulk
                      Upload
                    </a>
                  </p>
                </div>
                <button
                  type="submit"
                  className="button_common w-100 mt-2 mb-0 text-center"
                  role="button"
                  // onClick={submit}
                >
                  Upload
                </button>
              </Modal>
              {/* **************** View Modal Starts ************************** */}
            </span>

            {/* <div className='w-50 mt-2 sort-filter position-absolute'>
                            <div className='col px-0 employee-dropdown d-flex justify-content-end align-items-center'>
                                <Dropdown className='float-end'>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <div className='icon-div me-0'><i className="fa fa-filter"></i></div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        < NavLink to={AdminRoute.Common.Profile}>Employee</NavLink><br></br>
                                        < NavLink to={AdminRoute.Common.Payment}>Leader</NavLink><br></br>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div> */}

            <div className="col-md-12 mb-2 list-item organization-details-v-height">
              <div className="sessions-tabs mt-2">
                <Tabs
                  defaultActiveKey="active"
                  id="uncontrolled-tab-example"
                  className="mb-3 px-0"
                  onSelect={handleSelect}
                >
                  <Tab eventKey="active" title="Active">
                    <div className="organizations-list mt-4">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Employee ID</th>
                            <th scope="col">Employee Name</th>
                            <th scope="col">Organization Name</th>
                            <th scope="col">Email</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {LoadingCmsemployee ? (
                            <figure>
                              <div className="dot white"></div>
                              <div className="dot"></div>
                              {/* <div className="dot"></div> */}
                              {/* <div className="dot"></div>
                                                            <div className="dot"></div> */}
                            </figure>
                          ) : currentItems.length === 0 ? (
                            <div
                              className="alert alert-warning digital-notes-alert"
                              role="alert"
                            >
                              Data Not Found
                            </div>
                          ) : (
                            currentItems &&
                            currentItems.map((val, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{val?.fullname}</td>
                                {/* <td>{val?.name} {val?.last_name}</td> */}
                                <td>{val?.email}</td>
                                <td>{val?.phone}</td>
                                <td>
                                  {moment(val?.createdAt).format("DD/MM/YYYY")}
                                </td>
                                <td>
                                  <div className="active px-3 py-2 rounded-pill text-white green text-center">
                                    Active
                                  </div>
                                </td>
                                {/* <td><button className='details bg-white px-3 py-2 mx-2 rounded-pill border border-dark'>View Details</button></td> */}
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div>
                        <Pagination>
                          <Pagination.First
                            onClick={() => {
                              setCurrentPage(1);
                            }}
                          />
                          <Pagination.Prev
                            onClick={() => {
                              setCurrentPage(currentPage>1 ?currentPage - 1:1);
                            }}
                          />
                          {Array.from({ length: totalPages }).map(
                            (_, index) => (
                              <Pagination.Item
                                key={index}
                                active={index + 1 === currentPage}
                                onClick={() => handlePageChange(index + 1)}
                              >
                                {index + 1}
                              </Pagination.Item>
                            )
                          )}
                          <Pagination.Next
                            onClick={() => {
                              setCurrentPage(currentPage + 1);
                            }}
                          />
                          <Pagination.Last
                            onClick={() => {
                              setCurrentPage(totalPages);
                            }}
                          />
                        </Pagination>
                        <p className="mx-2">
                          Showing {currentPage} of {totalPages}{" "}
                        </p>
                      </div>
                    </div>
                  </Tab>

                  <Tab eventKey="inactive" title="Inactive">
                    <div className="organizations-list mt-4">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Employee ID</th>
                            <th scope="col">Employee Name</th>
                            <th scope="col">Organization Name</th>
                            <th scope="col">Email</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {LoadingCmsemployee ? (
                            <figure>
                              <div className="dot white"></div>
                              <div className="dot"></div>
                              {/* <div className="dot"></div> */}
                              {/* <div className="dot"></div>
                                                            <div className="dot"></div> */}
                            </figure>
                          ) : currentItemsinactive.length === 0 ? (
                            <div
                              className="alert alert-warning digital-notes-alert"
                              role="alert"
                            >
                              Data Not Found
                            </div>
                          ) : (
                            currentItemsinactive &&
                            currentItemsinactive.map((val, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{val?.fullname}</td>
                                {/* <td>{val?.name} {val?.last_name}</td> */}
                                <td>{val?.org_name}</td>
                                <td>{val?.email}</td>
                                <td>
                                  {moment(val?.createdAt).format("DD/MM/YYYY")}
                                </td>
                                <td>
                                  <div className="active px-3 py-2 rounded-pill text-white gray text-center">
                                    Inactive
                                  </div>
                                </td>
                                {/* <td><button className='details bg-white px-3 py-2 mx-2 rounded-pill border border-dark'>View Details</button></td> */}
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div>
                        <Pagination>
                          <Pagination.First
                            onClick={() => {
                              setCurrentPageinactive(1);
                            }}
                          />
                          <Pagination.Prev
                            onClick={() => {
                              setCurrentPageinactive(currentPage >1 ? currentPage - 1:1);
                            }}
                          />
                          {Array.from({ length: totalPagesinactive }).map(
                            (_, index) => (
                              <Pagination.Item
                                key={index}
                                active={index + 1 === currentPageinactive}
                                onClick={() =>
                                  handlePageChangeinactive(index + 1)
                                }
                              >
                                {index + 1}
                              </Pagination.Item>
                            )
                          )}
                          <Pagination.Next
                            onClick={() => {
                              setCurrentPage(currentPageinactive + 1);
                            }}
                          />
                          <Pagination.Last
                            onClick={() => {
                              setCurrentPage(totalPagesinactive);
                            }}
                          />
                        </Pagination>
                        <p className="mx-2">
                          Showing {currentPageinactive} of {totalPagesinactive}{" "}
                        </p>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetailsList;
