// import Page from "../Components/Page";
import React from "react";
import RouteName from "./RouteDetails";
import Login from "./../Components/Auth/Login";
import Dashboard from "../Components/Dashboard/Dashboard";
import Profile from "../Components/Dashboard/Profile";
import Settings from "../Components/Dashboard/Settings";
import ProtectedRoute from "./ProtectedRoute";
import Profileedit from "../Components/Dashboard/Profileedit";

// ----------------------Super Admin Routes----------------------
import Organizations from "../Components/Myorg/Organizations";
import OrganizationDetails from "../Components/Myorg/OrganizationDetails";
import EmployeeDetailsList from "../Components/Myorg/EmployeeDetailsList";
import Clients from "../Components/Clients/Clients";
import ClientsDetails from "../Components/Clients/ClientsDetails";
import Therapist from "../Components/Therapist/Therapist";
import TherapistDetails from "../Components/Therapist/TherapistDetails";
import Employees from "../Components/Employees/Employees";
import EmployeesDetails from "../Components/Employees/EmployeesDetails";
import WellnessCoach from "../Components/WellnessCoach/WellnessCoach";
import WellnessCoachDetails from "../Components/WellnessCoach/WellnessCoachDetails";
import ResearchTeam from "../Components/ResearchTeam/ResearchTeam";
import ResearchTeamDetails from "../Components/ResearchTeam/ResearchTeamDetails";
import PlaygroundList from "../Components/Playground/PlaygroundList";
import CreateContent from "../Components/Playground/CreateContent";
import Addwellnesscoach from "../Components/WellnessCoach/Addwellnesscoach";
import AddTherapist from "../Components/Therapist/AddTherapist";
import Addorganization from "../Components/Myorg/Addorganization"

//routes
const AdminRoute = [
  {
    path: RouteName.Auth.Login,
    element: <Login />,
    exact: true,
  },


  {
    path: RouteName.Common.Dashboard,
    element: (
      <ProtectedRoute
        redirectPath={`../${RouteName.Auth.Login}`}>
        <Dashboard />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: RouteName.Common.Profile,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Profile />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: RouteName.Common.Settings,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Settings />
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: `${RouteName.Common.Profileedit}`,
    element: <Profileedit />,
    exact: true,
  },

  // ----------------------Super Admin Routes----------------------

  {
    path: `${RouteName.Common.Organizations}`,
    element: (<ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}><Organizations />
    </ProtectedRoute>),
    exact: true,
  },
  {
    path: `${RouteName.Common.OrganizationDetails}/:id`,
    element: (<ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}><OrganizationDetails />
    </ProtectedRoute>),
    exact: true,
  },
  {
    path: `${RouteName.Common.EmployeeDetailsList}/:Cname`,
    element: (<ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}><EmployeeDetailsList />
    </ProtectedRoute>),
    exact: true,
  },
  {
    path: `${RouteName.Common.Clients}`,
    element: (<ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}><Clients />
    </ProtectedRoute>),
    exact: true,
  },
  {
    path: `${RouteName.Common.ClientsDetails}/:id`,
    element: (<ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}><ClientsDetails />
    </ProtectedRoute>),
    exact: true,
  },
  {
    path: `${RouteName.Common.Therapist}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}><Therapist />
      </ProtectedRoute>),
    exact: true,
  },
  {
    path: `${RouteName.Common.TherapistDetails}/:id`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}><TherapistDetails />
      </ProtectedRoute>),
    exact: true,
  },

  {
    path: RouteName.Common.Employees,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Employees />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Common.EmployeesDetails}/:id`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <EmployeesDetails />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Common.WellnessCoach}`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <WellnessCoach />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: `${RouteName.Common.WellnessCoachDetails}/:id`,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <WellnessCoachDetails />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.ResearchTeam,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <ResearchTeam />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.ResearchTeamDetails,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <ResearchTeamDetails />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.PlaygroundList,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <PlaygroundList />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.CreateContent,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <CreateContent />
      </ProtectedRoute>
    ),
    exact: true,
  },


  {
    path: RouteName.Common.AddTherapist,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <AddTherapist />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.Addwellnesscoach,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Addwellnesscoach />
      </ProtectedRoute>
    ),
    exact: true,
  },
  {
    path: RouteName.Common.Addorganization,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Addorganization />
      </ProtectedRoute>
    ),
    exact: true,
  },
];

export default AdminRoute;
