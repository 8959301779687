// Header.js
import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Dropdown from 'react-bootstrap/Dropdown';
import Sidebar from '../Common/Sidebar';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useState } from 'react';
import AdminRoute from '../../Route/RouteDetails';
import { NavLink } from "react-router-dom";
import API from "../../Api/Api";
import io from "socket.io-client";
import { formatDateTime } from "./Functions";
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useNavigate } from "react-router-dom";
const socket = io('https://ai.rytlife.com', { path: '/prodap/socket.io' });

function Header() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState([]);
  const [room] = useState("notification")
  const UserData = JSON.parse(localStorage.getItem("superadmin"));
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  useEffect(() => {
    socket.on(room, (data) => {
      setNotification((list) => [data, ...list]);
    });
    return () => { socket.removeListener(room); }
  }, [socket]);


  useEffect(() => {
    API.NotificationApis.getAllnotificationApi()
      .then((response) => {
        if (response.data.status === 1) {
          setNotification(response.data.data_all)
        }
      }).catch((error) => {
        console.log(error)
      })
  }, [])

  const handleMyChecklist = (val) => {
    navigate(`../${AdminRoute?.Common?.MyChecklist}`);
  }




  return (
    <div className="header">
      <div className="toggle-menu">
        <Dropdown className="d-inline" autoClose={false}>
          <Dropdown.Toggle id="dropdown-autoclose-false">

          </Dropdown.Toggle>



          <Button variant="primary" onClick={handleShow}>

            <Button className="btn mb-logo" id="collapseToggle">
              <span id="toggleIcon" className="navbar-toggler-icon" />
            </Button>


          </Button>

          <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Sidebar />
            </Offcanvas.Body>
          </Offcanvas>



          <Dropdown.Menu>

          </Dropdown.Menu>
        </Dropdown>
      </div>
      <ul className="navbar-nav ms-auto mt-lg-0 align-items-center">
        {/* <li className="checklist-wrap pe-1">
          <div className="checklist-icon icon-div cursor-pointer" onClick={() => handleMyChecklist()}>
            <img src='images/notes-white.png' className="User" alt="icon" />
          </div>
        </li> */}
        {/* <li className='notification-dropdown'>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <div className='icon-div p-0'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="icon icon-lg" role="img"><path fill="var(--ci-primary-color, currentColor)" d="M450.27,348.569,406.6,267.945V184c0-83.813-68.187-152-152-152s-152,68.187-152,152v83.945L58.928,348.568A24,24,0,0,0,80.031,384h86.935c-.238,2.636-.367,5.3-.367,8a88,88,0,0,0,176,0c0-2.7-.129-5.364-.367-8h86.935a24,24,0,0,0,21.1-35.431ZM310.6,392a56,56,0,1,1-111.419-8H310.018A56.14,56.14,0,0,1,310.6,392ZM93.462,352,134.6,276.055V184a120,120,0,0,1,240,0v92.055L415.736,352Z" className="ci-primary"></path></svg>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
             
                  <React.Fragment >
                   
                      <Dropdown.Item >
                        <div className="preview-item-joined flex-grow py-1">
                          <div className="notification-wrapper ps-2">
                            <p className="preview-subject ellipsis font-weight-medium text-dark">werewr</p>
                            <p className="fw-light small-text mb-0 grey-text">ererer</p>
                            <p className='date grey-text'>fgdf</p>
                          </div>
                        </div>
                      </Dropdown.Item>
                     
                        <Dropdown.Item >
                          <div className="preview-item-joined flex-grow py-1">
                            <div className="notification-wrapper ps-2">
                              <p className="preview-subject ellipsis font-weight-medium text-dark">rewrwer</p>
                              <p className="fw-light small-text mb-0 grey-text">dfsdf</p>
                              <p className='date grey-text'>dffdgffd</p>
                            </div>
                          </div>
                        </Dropdown.Item>
                    
                   
                  </React.Fragment>
               

            </Dropdown.Menu >

          </Dropdown>
        </li> */}

        {/* <li className='account-dropdown'>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              < NavLink to={AdminRoute.Common.Profile}>
                <div className='icon-div'><img src='images/user-white.png' className="User" alt="icon" /></div>

              </NavLink>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              < NavLink to={AdminRoute.Common.Profile}></NavLink><br></br>
              < NavLink to={AdminRoute.Common.Payment}>Payments</NavLink><br></br>
              < NavLink to={AdminRoute.Common.Settings}>Settings</NavLink><br></br>
              <Dropdown.Item href="/payment"></Dropdown.Item>
              <Dropdown.Item href="/settings"> </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </li> */}

        <li className='account-dropdown'>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <div className='icon-div'><img src='images/user-white.png' className="User" alt="icon" /></div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <p className="my-0 text-white text-end">{UserData?.data?.email}</p>
            </Dropdown.Menu>
          </Dropdown>
        </li>

      </ul>
    </div >

  );
}

export default Header;
