import React, { useState, useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { NavLink } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import moment from "moment";
import { Pagination } from "react-bootstrap";
const Clients = () => {
  const [Client, setClient] = useState([]);
  const [LoadingClient, setLoadingClient] = useState(false);
  const [tab, setTab] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = Client.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(Client.length / itemsPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getClientList(1);
  }, []);

  const getClientList = (Key) => {
    // console.log(Key);
    setLoadingClient(true);
    try {
      API.Common.CLientlisting({
        key: Key,
      }).then((response) => {
        // console.log(response.data);
        setClient(response?.data?.data_all);
        setLoadingClient(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingClient(false);
    }
  };

  const handleSelect = (Key) => {
    // console.log(Key);
    // if (Key === "all") {
    //     getClientList();
    //     console.log("all", Key);
    //     setTab(Key);
    // }
    if (Key === "active") {
      getClientList(1);
      // console.log("active", Key);
      setTab(Key);
    }
    if (Key === "inactive") {
      getClientList(0);
      // console.log("inactive", Key);
      setTab(Key);
    }
  };
  //jsx
  return (
    <div className="d-flex h-dashboard">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row list">
            <div className="">
              <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                <div className="w-100 h-head">
                  <h2 className="heading pt-0 mt-1 mb-1">Clients</h2>
                </div>
                <Header />
              </nav>
            </div>
            <div className="col-md-12 mb-2 list-item organization-details-v-height">
              <div className="sessions-tabs mt-2">
                <Tabs
                  defaultActiveKey="active"
                  id="uncontrolled-tab-example"
                  className="mb-3 px-0"
                  onSelect={handleSelect}
                >
                  {/* <Tab eventKey="all" title="All">
                                        <div className='organizations-list mt-4'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Client ID</th>
                                                        <th scope="col">Client Name</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Mobile No.</th>
                                                        <th scope="col">Registered Date</th>
                                                        <th scope="col"></th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {LoadingClient ? (
                                                        <figure>
                                                            <div className="dot white"></div>
                                                            <div className="dot"></div>
                                                          
                                                        </figure>
                                                    ) : currentItems.length === 0 ? (
                                                        <div
                                                            className="alert alert-warning digital-notes-alert"
                                                            role="alert"
                                                        >
                                                            Data Not Found
                                                        </div>
                                                    ) : (
                                                        currentItems &&
                                                        currentItems.map((val, index) => (
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{val?.name} {val?.last_name}</td>
                                                                <td>{val?.email}</td>
                                                                <td>{val?.phone}</td>
                                                                <td>{moment(val?.createdAt).format("LL")}</td>

                                                                <td>
                                                                    {val?.is_authorized === 1 ?
                                                                        <div className="active px-3 py-2 rounded-pill text-white green text-center" >
                                                                            Active
                                                                        </div>
                                                                        : <div className="active px-3 py-2 rounded-pill text-white gray text-center">
                                                                            Inactive
                                                                        </div>}
                                                                </td>
                                                                <td>< NavLink to={`${AdminRoute.Common.ClientsDetails}/${val?._id}`}><button className='details bg-white px-3 py-2 mx-2 rounded-pill border border-dark'>View Details</button></NavLink></td>
                                                            </tr>
                                                        )))}
                                                </tbody>
                                            </table>

                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <div>
                                                <Pagination>
                                                    <Pagination.First onClick={() => { setCurrentPage(1) }} />
                                                    <Pagination.Prev onClick={() => { setCurrentPage(currentPage - 1) }} />
                                                    {Array.from({ length: totalPages }).map((_, index) => (
                                                        <Pagination.Item
                                                            key={index}
                                                            active={index + 1 === currentPage}
                                                            onClick={() => handlePageChange(index + 1)}
                                                        >
                                                            {index + 1}
                                                        </Pagination.Item>
                                                    ))}
                                                    <Pagination.Next onClick={() => { setCurrentPage(currentPage + 1) }} />
                                                    <Pagination.Last onClick={() => { setCurrentPage(totalPages) }} />
                                                </Pagination>
                                                <p className='mx-2'>Showing {currentPage} of {totalPages} </p>
                                            </div>
                                        </div>
                                    </Tab> */}
                  <Tab eventKey="active" title="Active">
                    <div className="organizations-list mt-4">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Client ID</th>
                            <th scope="col">Client Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Mobile No.</th>
                            <th scope="col">Registered Date</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {LoadingClient ? (
                            <figure>
                              <div className="dot white"></div>
                              <div className="dot"></div>
                              {/* <div className="dot"></div> */}
                              {/* <div className="dot"></div>
                                                            <div className="dot"></div> */}
                            </figure>
                          ) : currentItems.length === 0 ? (
                            <div
                              className="alert alert-warning digital-notes-alert"
                              role="alert"
                            >
                              Data Not Found
                            </div>
                          ) : (
                            currentItems &&
                            currentItems.map((val, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>
                                  {val?.name} {val?.last_name}
                                </td>
                                <td>{val?.email}</td>
                                <td>{val?.phone}</td>
                                <td>
                                  {moment(val?.createdAt).format("DD/MM/YYYY")}
                                </td>
                                <td>
                                  <div className="active px-3 py-2 rounded-pill text-white green text-center">
                                    Active
                                  </div>
                                </td>
                                <td>
                                  <NavLink
                                    to={`${AdminRoute.Common.ClientsDetails}/${val?._id}`}
                                  >
                                    <button className="details bg-white px-3 py-2 mx-2 rounded-pill border border-dark">
                                      View Details
                                    </button>
                                  </NavLink>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div>
                        <Pagination>
                          <Pagination.First
                            onClick={() => {
                              setCurrentPage(1);
                            }}
                          />
                          <Pagination.Prev
                            onClick={() => {
                              setCurrentPage(currentPage >1 ?currentPage - 1:1);
                            }}
                          />
                          {Array.from({ length: totalPages }).map(
                            (_, index) => (
                              <Pagination.Item
                                key={index}
                                active={index + 1 === currentPage}
                                onClick={() => handlePageChange(index + 1)}
                              >
                                {index + 1}
                              </Pagination.Item>
                            )
                          )}
                          <Pagination.Next
                            onClick={() => {
                              setCurrentPage(currentPage + 1);
                            }}
                          />
                          <Pagination.Last
                            onClick={() => {
                              setCurrentPage(totalPages);
                            }}
                          />
                        </Pagination>
                        <p className="mx-2">
                          Showing {currentPage} of {totalPages}{" "}
                        </p>
                      </div>
                    </div>
                  </Tab>
                  {/* <Tab eventKey="inactive" title="Inactive">
                                        <div className='organizations-list mt-4'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Client ID</th>
                                                        <th scope="col">Client Name</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Mobile No.</th>
                                                        <th scope="col">Registered Date</th>
                                                        <th scope="col"></th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {LoadingClient ? (
                                                        <figure>
                                                            <div className="dot white"></div>
                                                            <div className="dot"></div>
                                                         
                                                        </figure>
                                                    ) : currentItems.length === 0 ? (
                                                        <div
                                                            className="alert alert-warning digital-notes-alert"
                                                            role="alert"
                                                        >
                                                            Data Not Found
                                                        </div>
                                                    ) : (
                                                        currentItems &&
                                                        currentItems.map((val, index) => (
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>{val?.name} {val?.last_name}</td>
                                                                <td>{val?.email}</td>
                                                                <td>{val?.phone}</td>
                                                                <td>{moment(val?.createdAt).format("LL")}</td>
                                                                <td><div className="active px-3 py-2 rounded-pill text-white gray text-center">Inactive</div></td>

                                                                <td>< NavLink to={`${AdminRoute.Common.ClientsDetails}/${val?._id}`}><button className='details bg-white px-3 py-2 mx-2 rounded-pill border border-dark'>View Details</button></NavLink></td>
                                                            </tr>
                                                        )))}
                                                </tbody>
                                            </table>

                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <div>
                                                <Pagination>
                                                    <Pagination.First onClick={() => { setCurrentPage(1) }} />
                                                    <Pagination.Prev onClick={() => { setCurrentPage(currentPage - 1) }} />
                                                    {Array.from({ length: totalPages }).map((_, index) => (
                                                        <Pagination.Item
                                                            key={index}
                                                            active={index + 1 === currentPage}
                                                            onClick={() => handlePageChange(index + 1)}
                                                        >
                                                            {index + 1}
                                                        </Pagination.Item>
                                                    ))}
                                                    <Pagination.Next onClick={() => { setCurrentPage(currentPage + 1) }} />
                                                    <Pagination.Last onClick={() => { setCurrentPage(totalPages) }} />
                                                </Pagination>
                                                <p className='mx-2'>Showing {currentPage} of {totalPages} </p>
                                            </div>
                                        </div>
                                    </Tab> */}
                </Tabs>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Clients;
