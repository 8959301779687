// App.js
import React from 'react';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import AdminRoute from '../../Route/RouteDetails';


function Dashboard() {
  const navigate = useNavigate()
  return (
    <div className="d-flex h-dashboard profile">
      <Sidebar />
      <div className='toggleIcon'>
        <div className="container-fluid">
          <div className="row">
            <div className="row">
              <nav className="navbar navbar-expand-lg header-navbar p-0">
                <h2 className='heading  mt-1 mb-1'>Settings</h2>
                <Button
                  type="submit"
                  className="create-new_notes button_common notes-btn transparent-btn"
                  onClick={() => navigate(`../${AdminRoute.Common.Therapist}`)}
                >
                  Raise A Ticket
                </Button>

                <Header />
              </nav>
            </div>
            <div className="row mb-4">
              <p>Below are some system setting for you to curate a personalised feed</p>
              <div className="col-lg-6 col-md-12  mb-2">
                <div className='card-body payment-card-body'>
                  <h4>Themes</h4>
                  <p>Personalise your feed as per your need</p>
                  <Form.Select aria-label="Default select example" className='border-0'>
                    <option>Light</option>
                    <option value="2">Dark</option>
                    <option value="3">Gradient</option>
                  </Form.Select>
                </div>

              </div>
              <div className="col-lg-6 col-md-12  mb-2">
                <div className='card-body payment-card-body'>
                  <h4>Font</h4>
                  <p>Personalise your feed as per your need</p>
                  <Form.Select aria-label="Default select example" className='border-0'>
                    <option>Poppins</option>
                    <option value="2">Raleway</option>
                    <option value="3">Helvetica Nue</option>
                  </Form.Select>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div >

  );
}

export default Dashboard;
