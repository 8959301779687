// App.js
import React, { useState, useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Button from "react-bootstrap/Button";
import { NavLink, useNavigate } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import DefaultUserImg from "../../Assets/Images/icons/Icon awesome-user-circle.png";
import API from "../../Api/Api";
import baseApi from "../../Api/config";

function Addorganization() {
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const [ProfileImage, setProfileImage] = useState("");
  const initialValues = {
    Name: "",
    Email: "",
    PhoneNumber: "",
    birthDate: "",
    Gender: "",
    Password: "",
    Companyname: "",
    Companyaddress: "",
    email1: "",
    CNnumber: "",
    GSTnumber: "",
    Sector: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [Sector, Setsector] = useState("")

  const RegisterValidate = (values, Sector) => {
    const errors = {};
    // const checkMobile = /^[789]\d{9}$/
    const emailRegrex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;
    var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    const nameRegex = /^[A-Za-z\s-]+$/;
    const ageRegex = /^\d+$/;
    const minNumber = 1;
    const maxNumber = 120;
    
    if (!values?.Name) {
      errors.Name = "Please enter first name!";
    } else if (!nameRegex.test(values?.Name)) {
      errors.Name = "Please enter valid name!";
    }

    if (!values?.PhoneNumber) {
      errors.PhoneNumber = "Please enter mobile no!";
    }
    if (!values?.Email) {
      errors.Email = "Please enter email!";
    } else if (!emailRegrex.test(values?.Email)) {
      errors.Email = "Please enter valid email!";
    }
    if (!values?.email1) {
      errors.email1 = "Please enter email!";
    } else if (!emailRegrex.test(values?.email1)) {
      errors.email1 = "Please enter valid email!";
    }
    if (!values?.birthDate) {
      errors.birthDate = "Birth date is required";
    }

    // if (!values.GSTnumber) {
    //   errors.GSTnumber = "GST Number is required";
    // }else if (!ageRegex.test(values?.GSTnumber)) {
    //   errors.GSTnumber = "Please enter valid GST Number!";
    // } else if (values?.GSTnumber < minNumber || values?.GSTnumber > maxgstnum) {
    //   errors.GSTnumber = "Please enter valid GST Number!";
    // }

    if (!values?.Gender) {
      errors.Gender = "Please select gender!";
    }
    if (!values?.Password) {
      errors.Password = "Password is required";
    } else if (!regularExpression.test(values?.Password)) {
      errors.Password = "Enter valid Password";
    }
    if (!values?.Companyname) {
      errors.Companyname = "Company name is required";
    }
    if (!values.Companyaddress) {
      errors.Companyaddress = "Company address is required";
    }
    if (!values.CNnumber) {
      errors.CNnumber = "CN Number is required";
    }
   if (!values.GSTnumber) {
      errors.GSTnumber = "GST Number is required";
    }
    if (!Sector) {
      errors.Sector = "Sector Name is required";
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    // console.log(formData);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      //navigate(`../${AdminRoute?.Auth?.VerifyOtp}`);  // temporary baypass to verification page
      try {
        API.Common.Register({
          data: {
            fullname: formData?.Name,
            email: formData?.Email,
            phone: formData?.PhoneNumber,
            dob:formData?.birthDate,
            gender: formData?.Gender,
            password: formData?.Password,
            organization_name: formData?.Companyname,
            org_name: formData?.Companyname,
            Companyaddress: formData?.Companyaddress,
            CNnumber: formData?.CNnumber,
            Companyemail: formData?.email1,
            GSTnumber: formData?.GSTnumber,
            Sector: Sector,
            role: "Orgadmin",
            image: ProfileImage,
          },
        }).then((response) => {
          // console.log(response.data)
          if (response?.data?.status === 1) {
            navigate(`../${AdminRoute?.Common?.Organizations}`);
            // toast.success(response?.data?.message);
          }
          else {
            // toast.error(response?.data?.message);
          }
        })
          .catch((error) => {
            // console.log('Error', error.message);
            // console.log(error.response)
            console.log(error);
          });
      }
      catch (error) {
        console.log(error);
      }
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(RegisterValidate(formData, Sector));
    setIsSubmit(true);
  };
  const disableFutureDate = () => {
    // const today = new Date().toISOString().split('T')[0];
    // return today;
    const currentDate = new Date();
    const maxDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
    return maxDate.toISOString().split('T')[0];
  }

  const handleChangeimage = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  // ==========Upload image api===========

  const handleUpload = (e) => {
    // console.log(e.target.files[0].name);
    setSpinner(true);
    try {
      var formdata = new FormData();
      formdata.append("image", e.target.files[0], e.target.files[0].name);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${baseApi.baseurl}fileupload/image`, requestOptions)
        .then((response) => response.text())
        .then(
          (result) => addImage(JSON.parse(result).data?.filename),
          setSpinner(false)
        );
    } catch (err) {
      // console.log(err.res);
      setSpinner(false);
    }
  };

  const addImage = (image) => {
    setProfileImage(`${baseApi.baseurl}images/${image}`);
  };
  //jsx
  return (
    <div className="d-flex h-dashboard profile">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row">
            <div className="nav-wrap">
              <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                <h2 className="heading  mt-1 mb-1">
                  <i
                    className="fa fa-chevron-left cursor_pointer pe-2"
                    aria-hidden="true"
                    onClick={() => navigate(-1)}
                  ></i>{" "}
                  Add Organization Profile</h2>
                <Header />
              </nav>
            </div>
            <div className="details-wrap">
              <div className="row">
                <div className="col-md-12 b-0">
                  <div className="card-body payment-card-body p-0 profile-wrap">
                    <div className="row">
                      <div className="col-12">
                        <div className="profile-header-box">
                          <div className="col px-0 employee-dropdown setting-dropdown">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="profile-image-box ms-4 d-flex justify-content-center align-items-center">
                          {spinner ? (
                            <>
                              <span className="custom-drag-box">
                                <div className="m-5">
                                  <div className="container_loader "></div>
                                </div>
                                <input
                                  type="file"
                                  id="uploadFile"
                                  accept="image/png, image/gif, image/jpeg, image/jpg"
                                  title=" "
                                  onChange={(e) => handleChangeimage(e)}
                                />
                              </span>
                            </>
                          ) : ProfileImage ? (
                            <>
                              <span className="custom-drag-box">
                                <div className="d-flex flex-column align-items-center w-100 h-100">
                                  <i
                                    className="fa fa-2x fa-check-circle me-2 text-orange text-20px d-none"
                                    aria-hidden="true"
                                  ></i>
                                  <div
                                    className="form-upload-photo-preview2"
                                    style={{
                                      backgroundImage: `url(${ProfileImage})`,
                                    }}
                                  ></div>
                                  <span className="custom-drag-box-text d-none">
                                    Image Uploaded
                                  </span>
                                  <input
                                    type="file"
                                    id="uploadFile"
                                    accept="image/png, image/gif, image/jpeg, image/jpg"
                                    title=" "
                                    onChange={(e) => handleUpload(e)}
                                  />
                                </div>
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="custom-drag-box">
                                <img className="reg-user-img" src={DefaultUserImg} />
                                <input
                                  type="file"
                                  id="uploadFile"
                                  accept="image/png, image/gif, image/jpeg, image/jpg"
                                  title=" "
                                  onChange={(e) => handleUpload(e)}
                                />
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3 p-3">
                      {/* //personal details */}
                      <div className="col-lg-6">
                        <div className="col-12">
                          <h6 className="mb-2">Personal Details</h6>
                          <hr />
                        </div>
                        <form>
                          <div className="row">
                            <div className="col-12">
                              <div className="row">
                                <div className="col-12">
                                  <div className="mb-3">
                                    <label htmlFor="Full Name" className="form-label">
                                      Full Name
                                    </label>
                                    <input
                                      type="text"
                                      name="Name"
                                      placeholder="Enter your First name"
                                      onChange={(e) => handleChange(e)}
                                      autoComplete="off"
                                    />
                                    <p className="text-danger mb-0">{formErrors?.Name}</p>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label htmlFor="Email" className="form-label">
                                      Email
                                    </label>
                                    <input
                                      type="email"
                                      name="Email"
                                      placeholder="Enter your E-Mail ID"
                                      onChange={(e) => handleChange(e)}
                                      autoComplete="off"
                                    />
                                    <p className="text-danger mb-0">{formErrors?.Email}</p>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label htmlFor="Contact No" className="form-label">
                                      Contact No.
                                    </label>
                                    <input
                                      type="tel"
                                      id="myNumberInput"
                                      name="PhoneNumber"
                                      placeholder="Enter mobile no"
                                      maxLength={10}
                                      onChange={(e) => handleChange(e)}
                                      autoComplete="off"
                                    />
                                    <p className="text-danger mb-0">{formErrors?.PhoneNumber}</p>
                                  </div>
                                </div>
                                <div className="col-12 col-lg-6">
                  <label><span className="text-danger fw-bold">*</span> Birth Date:</label>
                  <input
                    type="date"
                    placeholder="Enter BirthDate"
                    name="birthDate"
                    value={formData.birthDate}
                    onChange={handleChange}
                    autoComplete="off"
                    max={disableFutureDate()}

                    required
                  />
                  <p className="text-danger">{formErrors?.birthDate}</p>
                </div>
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label htmlFor="Age" className="form-label">
                                      Address
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="Addtess"
                                      placeholder="Enter your Address"
                                      onChange={(e) => handleChange(e)}
                                    />
                                    <p className="text-danger mb-0">{formErrors?.Addtess}</p>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label htmlFor="Gender" className="form-label">
                                      Gender
                                    </label>
                                    <select
                                      name="Gender"
                                      className="time-select"
                                      onChange={(e) => handleChange(e)}
                                    >
                                      <option value="">Select Your Gender</option>
                                      <option value="Male">Male</option>
                                      <option value="Female">Female</option>
                                      <option value="other">other</option>
                                    </select>
                                    <p className="text-danger mb-0">{formErrors?.Gender}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      {/* //organization details */}
                      <div className="col-lg-6">
                        <div className="col-12">
                          <h6 className="mb-2">Organization Details</h6>
                          <hr />
                        </div>
                        <form>
                          <div className="row">
                            <div className="col-12">
                              <div className="row">
                                <div className="col-12">
                                  <div className="mb-3">
                                    <label htmlFor="Full Name" className="form-label">
                                      Company Name
                                    </label>
                                    <input
                                      type="text"
                                      name="Companyname"
                                      value={formData.Companyname}
                                      placeholder="Enter your Company Name"
                                      onChange={handleChange}
                                      autoComplete="off"
                                    />
                                    <p className="text-danger mb-0">{formErrors?.Companyname}</p>

                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label htmlFor="Email" className="form-label">
                                      Company Address
                                    </label>
                                    <input
                                      type="text"
                                      name="Companyaddress"
                                      value={formData.Companyaddress}
                                      placeholder="Enter your Company Address"
                                      onChange={handleChange}
                                      autoComplete="off"
                                    />
                                    <p className="text-danger mb-0">{formErrors?.Companyaddress}</p>

                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label htmlFor="Contact No" className="form-label">
                                      CAN NO.
                                    </label>
                                    <input
                                      type="number"
                                      name="CNnumber"
                                      value={formData.CNnumber}
                                      placeholder="Enter your CAN number"
                                      onChange={handleChange}
                                      autoComplete="off"
                                    />
                                    <p className="text-danger mb-0">{formErrors?.CNnumber}</p>

                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label htmlFor="Age" className="form-label">
                                      GST Number
                                    </label>
                                    <input
                                      type="number"
                                      name="GSTnumber"
                                      maxLength={16}
                                      value={formData.GSTnumber}
                                      placeholder="Enter your GST Number"
                                      onChange={handleChange}
                                      autoComplete="off"
                                    />
                                    <p className="text-danger mb-0">{formErrors?.GSTnumber}</p>

                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label htmlFor="Gender" className="form-label">
                                      Company Email
                                    </label>
                                    <input
                                      type="email"
                                      placeholder="Enter E-Mail ID"
                                      name="email1"
                                      value={formData.email1}
                                      autoComplete="off"
                                      onChange={handleChange}
                                      required
                                    />
                                    <p className="text-danger mb-0">{formErrors?.email1}</p>

                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="Years of experience"
                                      className="form-label"
                                    >
                                      Sector
                                    </label>
                                    <select
                                      className="time-select"
                                      onChange={(e) => Setsector(e.target.value)}
                                    >
                                      <option value="">Select Your Sector</option>
                                      <option value={"Finance"}>Finance</option>
                                      <option value={"Healthcare"}>Healthcare</option>
                                      <option value={"Technology"}>Technology</option>
                                      <option value={"Retail"}>Retail</option>
                                      <option value={"Energy"}>Energy</option>
                                      <option value={"Manufacturing"}>Manufacturing</option>
                                      <option value={"Transportation"}>Transportation</option>
                                      <option value={"Construction"}>Construction</option>
                                      <option value={"Agriculture"}>Agriculture</option>
                                      <option value={"Education"}>Education</option>
                                      <option value={"Hospitality"}>Hospitality</option>
                                      <option value={"Real-Estate"}>Real Estate </option>
                                      <option value={"Services"}>Services </option>
                                      <option value={"Media"}>Media</option>
                                      <option value="">Other</option>
                                    </select>
                                    <p className="text-danger">{formErrors?.Sector}</p>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="mb-3">
                                        <label htmlFor="Expertise" className="form-label">
                                          Password
                                        </label>
                                        <input
                                          type="text"
                                          placeholder="Enter Password"
                                          name="Password"
                                          value={formData.Password}
                                          autoComplete="off"
                                          onChange={handleChange}
                                          required
                                        />
                                        <p className="text-danger">{formErrors?.Password}</p>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <Button
                                type="submit"
                                className="create-new_notes button_common notes-btn transparent-btn mt-2 mt-0 mb-2 me-0 big-btn"
                                onClick={(e) => handleSubmit(e)}
                              >
                                Add Organization Profile
                              </Button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Addorganization;
