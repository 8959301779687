// App.js
import React, { useState, useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Button from "react-bootstrap/Button";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import DefaultUserImg from "../../Assets/Images/icons/Icon awesome-user-circle.png";
import API from "../../Api/Api";
import moment from "moment";

const TherapistDetails = () => {
  const navigate = useNavigate();
  const [TherapistDetails, setTherapistDetails] = useState([]);
  const [LoadingDetails, setLoadingDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const param = useParams();

  const GetDetails = () => {
    setLoadingDetails(true);
    // console.log(param?.id);
    try {
      API.Common.TherapistDetails({
        data: { _id: param?.id },
      }).then((response) => {
        if (response?.data?.status === 1) {
          setTherapistDetails(response?.data?.data);
          setLoadingDetails(false);
        }
      });
    } catch (error) {
      console.log(error);
      setLoadingDetails(false);
    }
  };

  useEffect(() => {
    GetDetails();
  }, []);

  //approve therpist api
  const GetApproveTherapist = (e) => {
    setLoading(true);
    // console.log(param?.id);
    try {
      API.Common.IsApproveall({
        data: {
          _id: param?.id,
          is_authorized: 1,
          role: "Therapist",
        },
      }).then((response) => {
        if (response?.data?.status === 1) {
          navigate(`../${AdminRoute?.Common?.Therapist}`);
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  //jsx
  return (
    <div className="d-flex h-dashboard profile">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          {LoadingDetails ? (
            <figure>
              <div className="dot white"></div>
              <div className="dot"></div>
              {/* <div className="dot"></div> */}
              {/* <div className="dot"></div>
                                                            <div className="dot"></div> */}
            </figure>
          ) : (
            <div className="row">
              <div className="">
                <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                  <h2 className="heading  mt-1 mb-1">
                    <i
                      className="fa fa-chevron-left cursor_pointer pe-2"
                      aria-hidden="true"
                      onClick={() => navigate(-1)}
                    ></i>{" "}
                    Therapist Details
                  </h2>
                  <Header />
                </nav>
              </div>

              <div className="row">
                <div className="col-md-12 b-0 pe-0">
                  <div className="card-body payment-card-body p-0 profile-wrap">
                    <div className="row">
                      <div className="col-12">
                        <div className="profile-header-box">
                          <div className="col px-0 employee-dropdown setting-dropdown"></div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div className="profile-image-box ms-4 d-flex justify-content-center align-items-center">
                          <img
                            src={
                              TherapistDetails?.image
                                ? TherapistDetails?.image
                                : DefaultUserImg
                            }
                          ></img>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-4 p-3">
                      <div className="col-12">
                        <h6 className="mb-2">Personal Details</h6>
                        <hr />
                      </div>

                      <form>
                        <div className="row">
                          <div className="col-7">
                            <div className="row">
                              <div className="col-12">
                                <div className="mb-3">
                                  <label
                                    htmlFor="Full Name"
                                    className="form-label"
                                  >
                                    Full Name
                                  </label>
                                  <input
                                    type="text"
                                    readOnly
                                    className="form-control"
                                    placeholder="Full name"
                                    aria-describedby="Full Name"
                                    defaultValue={TherapistDetails?.fullname}
                                  />
                                </div>
                              </div>

                              <div className="col-6">
                                <div className="mb-3">
                                  <label htmlFor="Email" className="form-label">
                                    Email
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    readOnly
                                    placeholder="Email"
                                    aria-describedby="Email"
                                    defaultValue={TherapistDetails?.email}
                                  />
                                </div>
                              </div>

                              <div className="col-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="Contact No"
                                    className="form-label"
                                  >
                                    Contact No.
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    readOnly
                                    placeholder="Contact No."
                                    aria-describedby="Contact No"
                                    defaultValue={TherapistDetails?.phone}
                                  />
                                </div>
                              </div>

                              <div className="col-6">
                                <div className="mb-3">
                                  <label htmlFor="Age" className="form-label">
                                    Date Of Birth
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    readOnly
                                    placeholder="Date of Birth"
                                    Value={moment(TherapistDetails?.dob).format(
                                      "DD/MM/YYYY"
                                    )}
                                  />
                                </div>
                              </div>

                              <div className="col-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="Gender"
                                    className="form-label"
                                  >
                                    Gender
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    readOnly
                                    placeholder="Gender"
                                    aria-describedby="Gender"
                                    defaultValue={TherapistDetails?.gender}
                                  />
                                </div>
                              </div>

                              <div className="col-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="Years of experience"
                                    className="form-label"
                                  >
                                    Years of experience
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    readOnly
                                    placeholder="Years of experience"
                                    aria-describedby="Years of experience"
                                    defaultValue={TherapistDetails?.experience}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-5">
                            <div className="row">
                              <div className="col-12">
                                <div className="mb-3">
                                  <label
                                    htmlFor="Expertise"
                                    className="form-label"
                                  >
                                    Expertise:
                                  </label>
                                  <textarea
                                    className="form-control"
                                    readOnly
                                    placeholder="Leave a comment here"
                                    rows="9"
                                    id="floatingTextarea"
                                    defaultValue={TherapistDetails?.experties}
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                          {TherapistDetails?.is_authorized === 0 ? (
                            <div className="col-12">
                              <Button
                                type="submit"
                                className="create-new_notes button_common notes-btn transparent-btn mt-2 mt-0"
                                onClick={(e) => GetApproveTherapist(e)}
                              >
                                {" "}
                                Profile Approve{" "}
                              </Button>
                              {loading ? (
                                <div className="loader_orange"></div>
                              ) : (
                                <></>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default TherapistDetails;
