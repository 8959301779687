// App.js
import React, { useState, useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import DefaultUserImg from "../../Assets/Images/icons/Icon awesome-user-circle.png";
import API from "../../Api/Api";
import baseApi from "../../Api/config";

const AddTherapist = () => {
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const [ProfileImage, setProfileImage] = useState("");
  const [loading, setLoading] = useState(false);
  const initialValues = {
    Name: "",
    Email: "",
    PhoneNumber: "",
    Age: "",
    Gender: "",
    YearOfExp: "",
    Expertise: "",
    Quote: "",
  };
  const [formData, setFormData] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [ApiError, setApiError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  // ==========Upload image api===========

  const handleUpload = (e) => {
    // console.log(e.target.files[0].name);
    setSpinner(true);
    try {
      var formdata = new FormData();
      formdata.append("image", e.target.files[0], e.target.files[0].name);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${baseApi.baseurl}fileupload/image`, requestOptions)
        .then((response) => response.text())
        .then(
          (result) => addImage(JSON.parse(result).data?.filename),
          setSpinner(false)
        );
    } catch (err) {
      // console.log(err.res);
      setSpinner(false);
    }
  };

  const addImage = (image) => {
    setProfileImage(`${baseApi.baseurl}images/${image}`);
  };

  const RegisterValidate = (values) => {
    // console.log("===> ok", values);
    const errors = {};
    const emailRegrex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;
    const nameRegex = /^[A-Za-z\s-]+$/;
    const ageRegex = /^\d+$/;
    const minNumber = 1;
    const maxNumber = 120;

    if (!values?.Name) {
      errors.Name = "Please enter first name!";
    } else if (!nameRegex.test(values?.Name)) {
      errors.Name = "Please enter valid name!";
    }

    if (!values?.PhoneNumber) {
      errors.PhoneNumber = "Please enter mobile no!";
    }
    if (!values?.Email) {
      errors.Email = "Please enter email!";
    } else if (!emailRegrex.test(values?.Email)) {
      errors.Email = "Please enter valid email!";
    }
    if (!values?.Age) {
      errors.Age = "Please enter age!";
    } else if (!ageRegex.test(values?.Age)) {
      errors.Age = "Please enter valid age!";
    } else if (values?.Age < minNumber || values?.Age > maxNumber) {
      errors.Age = "Please enter valid age!";
    }
    if (!values?.Gender) {
      errors.Gender = "Please select gender!";
    }

    if (!values?.YearOfExp) {
      errors.YearOfExp = "Year of Experience is required!";
    }
    if (!values?.Expertise) {
      errors.Expertise = "Your Expertise is required!";
    }
    if (!values?.Quote) {
      errors.Quote = "Quote is required!";
    }
    return errors;
  };

  useEffect(() => {
    // console.log(formData);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setLoading(true);
      try {
        API.Common.Register({
          data: {
            fullname: formData?.Name,
            email: formData?.Email,
            phone: formData?.PhoneNumber,
            age: formData?.Age,
            gender: formData?.Gender,
            experience: formData?.YearOfExp,
            experties: formData?.Expertise,
            qote: formData?.Quote,
            image: ProfileImage,
            role: "Therapist",
          },
        }).then((response) => {
          // console.log(response?.data);
          if (response?.data?.message === "User Create successfully") {
            setApiError(response?.data?.message);
            navigate(`../${AdminRoute?.Common?.Therapist}`);
          }
          if (response?.data?.message === "User Allready exists") {
            setApiError(response?.data?.message);
            setApiError(response?.data?.message);
            // toast.error(response?.data?.message);
          }
          setLoading(false);
        });
      } catch (error) {
        console.log(error);
        setApiError(error.message);
        setLoading(false);
      }
    }
  }, [formErrors]);


  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(RegisterValidate(formData));
    setIsSubmit(true);
  };
  //jsx
  return (
    <div className="d-flex h-dashboard profile">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row">
            <div className="row">
              <nav className="navbar navbar-expand-lg header-navbar p-0">
                <h2 className="heading  mt-1 mb-1">
                  <i
                    className="fa fa-chevron-left cursor_pointer pe-2"
                    aria-hidden="true"
                    onClick={() => navigate(-1)}
                  ></i>{" "}
                  Add Therapist Profile</h2>
                <Header />
              </nav>
            </div>
            <div className="row">
              <div className="col-md-12 b-0 pe-0">
                <div className="card-body payment-card-body p-0 profile-wrap">
                  <div className="row">
                    <div className="col-12">
                      <div className="profile-header-box">
                        <div className="col px-0 employee-dropdown setting-dropdown">

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="profile-image-box ms-4 d-flex justify-content-center align-items-center">
                        {spinner ? (
                          <>
                            <span className="custom-drag-box">
                              <div className="m-5">
                                <div className="container_loader "></div>
                              </div>
                              <input
                                type="file"
                                id="uploadFile"
                                accept="image/png, image/gif, image/jpeg, image/jpg"
                                title=" "
                                onChange={(e) => handleUpload(e)}
                              />
                            </span>
                          </>
                        ) : ProfileImage ? (
                          <>
                            <span className="custom-drag-box">
                              <div className="d-flex flex-column align-items-center w-100 h-100">
                                <i
                                  className="fa fa-2x fa-check-circle me-2 text-orange text-20px d-none"
                                  aria-hidden="true"
                                ></i>
                                <div
                                  className="form-upload-photo-preview2"
                                  style={{
                                    backgroundImage: `url(${ProfileImage})`,
                                  }}
                                ></div>
                                <span className="custom-drag-box-text d-none">
                                  Image Uploaded
                                </span>
                                <input
                                  type="file"
                                  id="uploadFile"
                                  accept="image/png, image/gif, image/jpeg, image/jpg"
                                  title=" "
                                  onChange={(e) => handleUpload(e)}
                                />
                              </div>
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="custom-drag-box">
                              <img className="reg-user-img" src={DefaultUserImg} />
                              <input
                                type="file"
                                id="uploadFile"
                                accept="image/png, image/gif, image/jpeg, image/jpg"
                                title=" "
                                onChange={(e) => handleUpload(e)}
                              />
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row mb-4 p-3">
                    <div className="col-12">
                      <h6 className="mb-2">Personal Details</h6>
                      <hr />
                    </div>
                    <form>
                      <div className="row">
                        <div className="col-7">
                          <div className="row">
                            <div className="col-12">
                              <div className="mb-3">
                                <label htmlFor="Full Name" className="form-label">
                                  Full Name
                                </label>
                                <input
                                  type="text"
                                  name="Name"
                                  placeholder="Enter your First name"
                                  onChange={(e) => handleChange(e)}
                                  autoComplete="off"
                                />
                                <p className="text-danger mb-0">{formErrors?.Name}</p>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-3">
                                <label htmlFor="Email" className="form-label">
                                  Email
                                </label>
                                <input
                                  type="email"
                                  name="Email"
                                  placeholder="Enter your E-Mail ID"
                                  onChange={(e) => handleChange(e)}
                                  autoComplete="off"
                                />
                                <p className="text-danger mb-0">{formErrors?.Email}</p>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-3">
                                <label htmlFor="Contact No" className="form-label">
                                  Contact No.
                                </label>
                                <input
                                  type="tel"
                                  id="myNumberInput"
                                  name="PhoneNumber"
                                  placeholder="Enter mobile no"
                                  maxLength={10}
                                  onChange={(e) => handleChange(e)}
                                  autoComplete="off"
                                />
                                <p className="text-danger mb-0">{formErrors?.PhoneNumber}</p>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-3">
                                <label htmlFor="Age" className="form-label">
                                  Age
                                </label>
                                <input
                                  name="Age"
                                  type="tel"
                                  id="myNumberInput"
                                  maxLength={3}
                                  placeholder="Enter your Age"
                                  onChange={(e) => handleChange(e)}
                                  autoComplete="off"
                                />
                                <p className="text-danger mb-0">{formErrors?.Age}</p>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-3">
                                <label htmlFor="Gender" className="form-label">
                                  Gender
                                </label>
                                <select
                                  name="Gender"
                                  className="gender-select"
                                  onChange={(e) => handleChange(e)}
                                >
                                  <option value="">Select Your Gender</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="other">other</option>
                                </select>
                                <p className="text-danger mb-0">{formErrors?.Gender}</p>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="Years of experience"
                                  className="form-label"
                                >
                                  Years of experience
                                </label>
                                <input
                                  type="number"
                                  id="myNumberInput"
                                  name="YearOfExp"
                                  placeholder="Enter Years of Experince"
                                  required
                                  onChange={(e) => handleChange(e)}
                                  autoComplete="off"
                                />
                                <p className="text-danger">{formErrors?.YearOfExp}</p>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="Years of experience"
                                  className="form-label"
                                >
                                  Quote
                                </label>
                                <input
                                  type="text"
                                  name="Quote"
                                  placeholder="Enter Quote you resonate with"
                                  onChange={(e) => handleChange(e)}
                                  autoComplete="off"
                                />
                                <p className="text-danger">{formErrors?.Quote}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="row">
                            <div className="col-12">
                              <div className="mb-3">
                                <label htmlFor="Expertise" className="form-label">
                                  Expertise:
                                </label>
                                <textarea
                                  rows="4"
                                  name="Expertise"
                                  className="form-input-text-area"
                                  placeholder="Enter your Expertise"
                                  onChange={(e) => handleChange(e)}
                                  autoComplete="off"
                                ></textarea>
                                <p className="text-danger">{formErrors?.Expertise}</p>
                              </div>
                              <p className="text-danger"> {ApiError}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <Button
                            type="submit"
                            className="create-new_notes button_common notes-btn transparent-btn mt-2 mt-0"
                            role="button"
                            disabled={
                              isSubmit === true && Object.keys(formErrors).length === 0
                                ? true
                                : false
                            }
                            onClick={(e) => handleSubmit(e)}
                          >
                            Add Therapist Profile{" "}
                          </Button>
                          {loading ? <div className="loader_orange"></div> : <></>}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default AddTherapist;
