import React, { useState, useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { NavLink } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";
import { Pagination } from "react-bootstrap";
import plusIcon from "../../Assets/Images/Icon feather-plus.png";
import Modal from "react-modal";
import baseApi from "../../Api/config";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // height: "500px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#FFF",
    borderRadius: "20px",
    paddingTop: "20px",
    width: "90%",
    maxWidth: "500px",
    boxShadow: "0px 0px 24px -10px #bdbdbd",
  },
};

const WellnessCoach = () => {
  const [BulkUpload, setBulkUpload] = useState("");
  const AuthToken = localStorage.getItem("AuthTokensuperadmin");
  const [Wellnesscoach_Listnew, setWellnesscoach_Listnew] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    getWellnesscoachnewcountapi();
  }, []);
  // const getWellnesscoachList = (Key) => {
  //     console.log(Key);
  //     setLoading(true)
  //     try {
  //         API.Common.WellnessCoachlisting({
  //             key: Key,
  //         }).then((response) => {
  //             console.log(response.data);
  //             setWellnesscoach_List(response?.data?.data_all);
  //             setLoading(false)
  //         });
  //     } catch (error) {
  //         console.log(error);
  //         setLoading(false)
  //     }
  // };

  const getWellnesscoachnewcountapi = (Key) => {
    // console.log(Key);
    setLoading(true);
    try {
      API.Common.WellnessCoachlistingnew({
        key: Key,
      }).then((response) => {
        // console.log(response.data_all);
        setWellnesscoach_Listnew(response?.data?.data);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  //pagination
  const currentItems = Wellnesscoach_Listnew?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(Wellnesscoach_Listnew?.length / itemsPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  //functon on tab press
  const handleSelect = (Key) => {
    // console.log(Key);
    if (Key === "all") {
      getWellnesscoachnewcountapi();
      setTab(Key);
    }
    if (Key === "active") {
      getWellnesscoachnewcountapi(1);
      setTab(Key);
    }
    if (Key === "inactive") {
      getWellnesscoachnewcountapi(0);
      setTab(Key);
    }
    if (Key === "Newdata") {
      getWellnesscoachnewcountapi(0);
      setTab(Key);
    }
  };
  //bulk upload

  //bulk upload api call
  const BulkUploadServiceCenter = (e) => {
    // console.log(e.target.files);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", AuthToken);
    var formdata = new FormData();
    formdata.append("csv", e.target.files[0], e.target.files[0]?.name);
    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${baseApi.baseurl}users/user_upload`, requestOptions)
      .then((response) => response.text())
      .then((result) => handleFileState(result))
      .catch((error) => console.log("error", error));
    window.$(".bulkupload").modal("hide");
  };

  const handleFileState = (result) => {
    setBulkUpload(JSON.parse(result));
    // console.log("object=====>>>", BulkUpload);
  };
  //download function
  const download = (e) => {
    e.preventDefault();
    // alert("Download");
    const response = {
      file_path: "https://ai.rytlife.com/prodap/images/1710870714_.csv",
    };
    window.open(response.file_path);
  };
  //js

  //jsx
  return (
    <div className="d-flex h-dashboard">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row list">
            <div className="">
              <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                <div className="w-100 h-head">
                  <h2 className="heading pt-0 mt-1 mb-1">Wellness Coach</h2>
                </div>
                <Header />
              </nav>
            </div>
            <span className="my-checklist-modal">
              <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
              >
                <button onClick={closeModal} className="close-btn ">
                  <img src={plusIcon} />{" "}
                </button>
                <div>
                  <input
                    type="text"
                    placeholder="Bulk Uploads"
                    className="my-task-add"
                  />
                  <input
                    type="File"
                    placeholder="Attach File"
                    className="my-3 mt-4 border rounded-end w-100"
                    name="File"
                    id="bulkupload"
                    onChange={(e) => BulkUploadServiceCenter(e)}
                  />
                  {/* <p className="text-danger">{formErrors?.Title}</p> */}

                  <p className="mb-4 mt-2">
                    <a
                      href="#"
                      className="text-dark text-decoration-underline"
                      onClick={(e) => download(e)}
                    >
                      Download a Blank Sample Template CSV File HTML for Bulk
                      Upload
                    </a>
                  </p>
                </div>
                <button
                  type="submit"
                  className="button_common w-100 mt-2 mb-0 text-center"
                  role="button"
                  // onClick={submit}
                >
                  Upload
                </button>
              </Modal>
              {/* **************** View Modal Starts ************************** */}
            </span>
            <div className="w-50 mt-2 sort-filter position-absolute">
              <div className="col px-0 employee-dropdown d-flex justify-content-end align-items-center">
                {/* <Dropdown className='float-end'>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <div className='icon-div me-0'><i className="fa fa-filter"></i></div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        < NavLink to={AdminRoute.Common.Profile}>Employee</NavLink><br></br>
                                    </Dropdown.Menu>
                                </Dropdown> */}
                <button
                  type="submit"
                  className="create-new_notes button_common notes-btn transparent-btn btn btn-primary mb-0 mt-0 ms-3"
                  onClick={openModal}
                >
                  Bulk Upload
                </button>
                <NavLink to={AdminRoute.Common.Addwellnesscoach}>
                  <button
                    type="submit"
                    className="create-new_notes notes-btn btn btn-primary mb-0 mt-0 ms-2 me-0 button_common approve-btn big-btn"
                  >
                    Add Wellness Caoch
                  </button>
                </NavLink>
              </div>
            </div>
            <div className="col-md-12 mb-2 list-item organization-details-v-height">
              <div className="sessions-tabs mt-2">
                <Tabs
                  defaultActiveKey="all"
                  id="uncontrolled-tab-example"
                  className="mb-3 px-0"
                  onSelect={handleSelect}
                >
                  <Tab eventKey="all" title="All">
                    <div className="organizations-list mt-4">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Wellness Coach ID</th>
                            <th scope="col">Wellness Coach</th>
                            <th scope="col">Email</th>
                            <th scope="col">Assigned Employees</th>
                            <th scope="col">Assigned Organisation</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <figure>
                              <div className="dot white"></div>
                              <div className="dot"></div>
                              {/* <div className="dot"></div> */}
                              {/* <div className="dot"></div>
                                                            <div className="dot"></div> */}
                            </figure>
                          ) : currentItems.length === 0 ? (
                            <div
                              className="alert alert-warning digital-notes-alert"
                              role="alert"
                            >
                              Data Not Found
                            </div>
                          ) : (
                            currentItems &&
                            currentItems.map((val, i) => (
                              <tr>
                                {/* {console.log("======",val)} */}
                                <td>{i + 1}</td>
                                <td>{val?.fullname}</td>
                                <td>{val?.email}</td>
                                <td>{val?.noOfemployees?.length}</td>
                                <td>{val?.assignOrgs?.length}</td>
                                <td>
                                  {val?.is_authorized === 1 ? (
                                    <div className="active px-3 py-2 rounded-pill text-white green text-center">
                                      Active
                                    </div>
                                  ) : (
                                    <div className="active px-3 py-2 rounded-pill text-white gray text-center">
                                      Inactive
                                    </div>
                                  )}
                                </td>

                                <td>
                                  <NavLink
                                    to={`${AdminRoute?.Common?.WellnessCoachDetails}/${val?._id}`}
                                  >
                                    <button className="details bg-white px-3 py-2 mx-2 rounded-pill border border-dark">
                                      View Details
                                    </button>
                                  </NavLink>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div>
                        <Pagination>
                          <Pagination.First
                            onClick={() => {
                              setCurrentPage(1);
                            }}
                          />
                          <Pagination.Prev
                            onClick={() => {
                              setCurrentPage(currentPage >1 ?currentPage - 1:1);
                            }}
                          />
                          {Array.from({ length: totalPages }).map(
                            (_, index) => (
                              <Pagination.Item
                                key={index}
                                active={index + 1 === currentPage}
                                onClick={() => handlePageChange(index + 1)}
                              >
                                {index + 1}
                              </Pagination.Item>
                            )
                          )}
                          <Pagination.Next
                            onClick={() => {
                              setCurrentPage(currentPage + 1);
                            }}
                          />
                          <Pagination.Last
                            onClick={() => {
                              setCurrentPage(totalPages);
                            }}
                          />
                        </Pagination>
                        <p className="mx-2">
                          Showing {currentPage} of {totalPages}{" "}
                        </p>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="active" title="Active">
                    <div className="organizations-list mt-4">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Wellness Coach ID</th>
                            <th scope="col">Wellness Coach</th>
                            <th scope="col">Email</th>
                            <th scope="col">Assigned Employees</th>
                            <th scope="col">Assigned Organisation</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <figure>
                              <div className="dot white"></div>
                              <div className="dot"></div>
                              {/* <div className="dot"></div> */}
                              {/* <div className="dot"></div>
                                                            <div className="dot"></div> */}
                            </figure>
                          ) : currentItems.length === 0 ? (
                            <div
                              className="alert alert-warning digital-notes-alert"
                              role="alert"
                            >
                              Data Not Found
                            </div>
                          ) : (
                            currentItems &&
                            currentItems.map((val, i) => (
                              <tr>
                                <td>{i + 1}</td>
                                <td>{val?.fullname}</td>
                                <td>{val?.email}</td>
                                <td>{val?.experience}Years</td>
                                <td>
                                  {moment(val?.createdAt).format("DD/MM/YYYY")}
                                </td>
                                <td>
                                  <div className="active px-3 py-2 rounded-pill text-white green text-center">
                                    Active
                                  </div>
                                </td>
                                <td>
                                  <NavLink
                                    to={`${AdminRoute?.Common?.WellnessCoachDetails}/${val?._id}`}
                                  >
                                    <button className="details bg-white px-3 py-2 mx-2 rounded-pill border border-dark">
                                      View Details
                                    </button>
                                  </NavLink>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div>
                        <Pagination>
                          <Pagination.First
                            onClick={() => {
                              setCurrentPage(1);
                            }}
                          />
                          <Pagination.Prev
                            onClick={() => {
                              setCurrentPage(currentPage >1 ? currentPage - 1:1);
                            }}
                          />
                          {Array.from({ length: totalPages }).map(
                            (_, index) => (
                              <Pagination.Item
                                key={index}
                                active={index + 1 === currentPage}
                                onClick={() => handlePageChange(index + 1)}
                              >
                                {index + 1}
                              </Pagination.Item>
                            )
                          )}
                          <Pagination.Next
                            onClick={() => {
                              setCurrentPage(currentPage + 1);
                            }}
                          />
                          <Pagination.Last
                            onClick={() => {
                              setCurrentPage(totalPages);
                            }}
                          />
                        </Pagination>
                        <p className="mx-2">
                          Showing {currentPage} of {totalPages}{" "}
                        </p>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="inactive" title="Inactive">
                    <div className="organizations-list mt-4">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Wellness Coach ID</th>
                            <th scope="col">Wellness Coach</th>
                            <th scope="col">Email</th>
                            <th scope="col">Assigned Employees</th>
                            <th scope="col">Assigned Organisation</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <figure>
                              <div className="dot white"></div>
                              <div className="dot"></div>
                              {/* <div className="dot"></div> */}
                              {/* <div className="dot"></div>
                                                            <div className="dot"></div> */}
                            </figure>
                          ) : currentItems.length === 0 ? (
                            <div
                              className="alert alert-warning digital-notes-alert"
                              role="alert"
                            >
                              Data Not Found
                            </div>
                          ) : (
                            currentItems &&
                            currentItems.map((val, i) => (
                              <tr>
                                <td>{i + 1}</td>
                                <td>{val?.fullname}</td>
                                <td>{val?.email}</td>
                                <td>{val?.experience}Years</td>
                                <td>
                                  {moment(val?.createdAt).format("DD/MM/YYYY")}
                                </td>
                                <td>
                                  <div className="active px-3 py-2 rounded-pill text-white gray text-center">
                                    Inactive
                                  </div>
                                </td>

                                <td>
                                  <NavLink
                                    to={`${AdminRoute?.Common?.WellnessCoachDetails}/${val?._id}`}
                                  >
                                    <button className="details bg-white px-3 py-2 mx-2 rounded-pill border border-dark">
                                      View Details
                                    </button>
                                  </NavLink>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div>
                        <Pagination>
                          <Pagination.First
                            onClick={() => {
                              setCurrentPage(1);
                            }}
                          />
                          <Pagination.Prev
                            onClick={() => {
                              setCurrentPage(currentPage >1 ?currentPage - 1:1);
                            }}
                          />
                          {Array.from({ length: totalPages }).map(
                            (_, index) => (
                              <Pagination.Item
                                key={index}
                                active={index + 1 === currentPage}
                                onClick={() => handlePageChange(index + 1)}
                              >
                                {index + 1}
                              </Pagination.Item>
                            )
                          )}
                          <Pagination.Next
                            onClick={() => {
                              setCurrentPage(currentPage + 1);
                            }}
                          />
                          <Pagination.Last
                            onClick={() => {
                              setCurrentPage(totalPages);
                            }}
                          />
                        </Pagination>
                        <p className="mx-2">
                          Showing {currentPage} of {totalPages}{" "}
                        </p>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default WellnessCoach;
