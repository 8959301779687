import React, { useState, useEffect } from 'react';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import AdminRoute from '../../Route/RouteDetails';
import API from '../../Api/Api';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';


const CreateContent = () => {
    const navigate = useNavigate();
    const [sumarySessionList, setSumarySessionList] = useState([]);
    const [upcomingSessionList, setUpcomingSessionList] = useState([]);
    const [loadSumaryList, setLoadSumaryList] = useState(false);
    const [loadUpcomingList, setLoadUpcomingList] = useState(false);


    useEffect(() => {
        getSumarySessionList()
        getUpcomingSessionList()
    }, [])

    // get summary list
    const getSumarySessionList = () => {
        setLoadSumaryList(true)
        try {
            API.Session.sumarySessionList().then((response) => {
                if (response.status === 200) {
                    setSumarySessionList(response?.data?.data_all);
                    setLoadSumaryList(false)
                }
            });
        } catch (error) {
            console.log(error);
            setLoadSumaryList(false)
        }
    }

    const getUpcomingSessionList = () => {
        setLoadUpcomingList(true)
        try {
            API.Session.upcomingSessionList().then((response) => {
                if (response.status === 200) {
                    setUpcomingSessionList(response?.data?.data_all);
                    setLoadUpcomingList(false)
                }
            });
        } catch (error) {
            console.log(error);
            setLoadUpcomingList(false)
        }
    }

    const handleEmployeeDetails = (val) => {
        navigate(`../${AdminRoute?.Common?.EmployeeDetails}`);
    }

    return (
        <div className="d-flex h-dashboard">
            <Sidebar />
            <div className='toggleIcon'>
                <div className="container-fluid">
                    <div className="row list create-content">
                        <div className="">
                            <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                                <div className='w-100 h-head'>
                                    <h2 className='heading pt-0 mt-1 mb-1'>
                                        <i
                                            className="fa fa-chevron-left cursor_pointer pe-2"
                                            aria-hidden="true"
                                            onClick={() => navigate(-1)}
                                        ></i>{" "}
                                        Create Content</h2>
                                </div>
                                <Header />
                            </nav>
                        </div>

                        <div className='mt-2 mb-3'>
                            <div className='col'>
                                <select className="form-select bg-white" aria-label="Default select example">
                                    <option selected>Mental Health Resources</option>
                                    <option value="1">Videos</option>
                                    <option value="2">Self Assessment Tests</option>
                                </select>
                            </div>
                        </div>
                        <div className="content-wrap">
                            <div className="col-md-12 b-0 pe-0 profile">
                                <div className="card-body payment-card-body p-0 profile-wrap">
                                    <div className="row p-3">
                                        <form>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="Title" className="form-label">
                                                                    Title
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Title"
                                                                    aria-describedby="Title"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="Author" className="form-label">
                                                                    Author
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Author Name"
                                                                    aria-describedby="Author"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="Creator" className="form-label">
                                                                    Creator
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Creator Name"
                                                                    aria-describedby="Creator"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="Type" className="form-label">
                                                                    Type
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Type"
                                                                    aria-describedby="Type"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="Published Date" className="form-label">
                                                                    Published Date
                                                                </label>
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    placeholder="Enter Published Date"
                                                                    aria-describedby="Published Date"
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>


                                                <div className="col-12">
                                                    <Button
                                                        type="submit"
                                                        className="create-new_notes button_common notes-btn transparent-btn mt-2 mt-0 me-0"
                                                    >
                                                        <NavLink to={AdminRoute?.Common?.CreateContent}>
                                                            Create{" "}
                                                        </NavLink>
                                                    </Button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div >






    );
}

export default CreateContent;
