import React, { useState, useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { NavLink } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import API from "../../Api/Api";
import moment from "moment";
import { Pagination } from "react-bootstrap";

const Employees = () => {
  const [Cmsemployee, setCmsemployee] = useState([]);
  const [Loading, setLoading] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedByOrg, setSelectedByOrg] = useState("");
  const [LoadingCmsemployee, setLoadingCmsemployee] = useState(false);
  const [LoadingCmsemployeeinactive, setLoadingCmsemployeeinactive] =
    useState(false);
  const [MyorgList, setMyorgList] = useState([]);

  const [totalcount, settotalcount] = useState();

  const [tab, setTab] = useState("active");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  useEffect(() => {
    if (tab === "active") {
      getEmployeeList(1);
    }
    if (tab === "inactive") {
      getEmployeeListInactive();
    }
    orGList();
  }, [currentPage, itemsPerPage, tab]);

  //active list api call
  const getEmployeeList = (Key) => {
    // console.log(Key);
    setLoadingCmsemployee(true);
    try {
      API.Common.Employeelisting({
        key: Key,
        itemsPerPage: itemsPerPage,
        currentPage: currentPage,
        org_name: selectedByOrg,
      }).then((response) => {
        settotalcount(response?.data?.totalCount);
        setCmsemployee(response?.data?.data_all);
        setLoadingCmsemployee(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingCmsemployee(false);
    }
  };

  const currentItems = Cmsemployee;
  console.log("currentItems", currentItems, "Cmsemployee", Cmsemployee);
  // const currentItems = Cmsemployee.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(totalcount / itemsPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSelect = (Key) => {
    // console.log(Key);

    if (Key === "active") {
      getEmployeeList(1);
      setTab(Key);
      setCurrentPage(1);
    }
    if (Key === "inactive") {
      getEmployeeListInactive();
      setTab(Key);
      setCurrentPage(1);
    }
  };
  //inactive tab api call
  const getEmployeeListInactive = (Key) => {
    // console.log(Key);
    setLoadingCmsemployeeinactive(true);
    try {
      API.Common.getInactiveEmployeelist({
        key: Key,
        itemsPerPage: itemsPerPage,
        currentPage: currentPage,
        org_name: selectedByOrg,
      }).then((response) => {
        // console.log("+++++++++", response.data);
        settotalcount(response?.data?.totalCount);
        setCmsemployee(response?.data?.data_all);
        setLoadingCmsemployeeinactive(false);
      });
    } catch (error) {
      console.log(error);
      setLoadingCmsemployeeinactive(false);
    }
  };

  //filter api
  const LeadSearch = () => {
    // console.log(text);
    setLoading(true);
    try {
      API.Common.Filterapiemployee({
        isLead: selectedOption,
        org_name: selectedByOrg,
      }).then((response) => {
        setCmsemployee(response?.data?.data);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const orGList = () => {
    try {
      API.Common.MyOrgListforwellnesscoach({}).then((response) => {
        if (response.data.status === 1) {
          // console.log("myorg", response?.data?.data);
          setMyorgList(response?.data?.data_all);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    LeadSearch();
  }, [selectedOption, selectedByOrg]);

  //jsx
  return (
    <div className="d-flex h-dashboard">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="row list">
            <div className="">
              <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                <div className="w-100 h-head">
                  <h2 className="heading pt-0 mt-1 mb-1">Employees</h2>
                </div>
                <Header />
              </nav>
            </div>

            <div className="w-50 mt-2 sort-filter position-absolute">
              <div className="col px-0 employee-dropdown d-flex justify-content-end align-items-center">
                <select
                  id="dropdown-basic"
                  className="form-control form-select me-2 px-4 search-select w-100 mb-2 mb-md-0 bg-white"
                  aria-label="Default select example"
                  value={selectedByOrg}
                  onChange={(e) => setSelectedByOrg(e.target.value)}
                >
                  <option selected value="">
                    Select Organization
                  </option>
                  {MyorgList.map((val, i) => (
                    <React.Fragment key={i}>
                      <option value={val.org_name}>{val.org_name}</option>
                    </React.Fragment>
                  ))}
                </select>

                <select
                  id="dropdown-basic"
                  className="form-control form-select me-2 px-4 search-select w-100 mb-2 mb-md-0 bg-white"
                  aria-label="Default select example"
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                >
                  <option selected value="">
                    Select Employee Type
                  </option>
                  <option value="no">Employee</option>
                  <option value="yes">Leader</option>
                </select>
                {/* <button type="submit" className="create-new_notes button_common notes-btn transparent-btn btn btn-primary mb-0 mt-0 ms-3" onClick={openModal}>Bulk Upload</button>
                                <button type="submit" className="create-new_notes notes-btn btn btn-primary mb-0 mt-0 ms-2 me-0 button_common approve-btn"><a className="" href="#">Add Employees</a></button> */}
              </div>
            </div>

            <div className="col-md-12 mb-2 list-item organization-details-v-height">
              <div className="sessions-tabs mt-2">
                <Tabs
                  defaultActiveKey="active"
                  id="uncontrolled-tab-example"
                  className="mb-3 px-0"
                  onSelect={handleSelect}
                >
                  <Tab eventKey="active" title="Active">
                    <div className="organizations-list mt-4">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Employee ID</th>
                            <th scope="col">Employee Name</th>
                            <th scope="col">Organization Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Register Date</th>
                            <th scope="col">Status</th>
                            <th scope="col">Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {LoadingCmsemployee ? (
                            <figure>
                              <div className="dot white"></div>
                              <div className="dot"></div>
                              {/* <div className="dot"></div> */}
                              {/* <div className="dot"></div>
                                                            <div className="dot"></div> */}
                            </figure>
                          ) : currentItems.length === 0 ? (
                            <div
                              className="alert alert-warning digital-notes-alert"
                              role="alert"
                            >
                              Data Not Found
                            </div>
                          ) : (
                            currentItems &&
                            currentItems.map((val, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{val?.fullname}</td>
                                {/* <td>{val?.name} {val?.last_name}</td> */}
                                <td>{val?.org_name}</td>
                                <td>{val?.email}</td>
                                <td>
                                  {moment(val?.createdAt).format("DD/MM/YYYY")}
                                </td>
                                <td>
                                  <div className="active px-3 py-2 rounded-pill text-white green text-center">
                                    Active
                                  </div>
                                </td>
                                <td>
                                  <NavLink
                                    to={`${AdminRoute?.Common?.EmployeesDetails}/${val?._id}`}
                                  >
                                    <button className="details bg-white px-3 py-2 mx-2 rounded-pill border border-dark">
                                      View Details
                                    </button>
                                  </NavLink>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Tab>

                  <Tab eventKey="inactive" title="Inactive">
                    <div className="organizations-list mt-4">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Employee ID</th>
                            <th scope="col">Employee Name</th>
                            <th scope="col">Organization Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Register Date</th>

                            <th scope="col">Status</th>
                            {/* <th scope="col">Details</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {LoadingCmsemployeeinactive ? (
                            <figure>
                              <div className="dot white"></div>
                              <div className="dot"></div>
                              {/* <div className="dot"></div> */}
                              {/* <div className="dot"></div>
                                                            <div className="dot"></div> */}
                            </figure>
                          ) : currentItems.length === 0 ? (
                            <div
                              className="alert alert-warning digital-notes-alert"
                              role="alert"
                            >
                              Data Not Found
                            </div>
                          ) : (
                            currentItems &&
                            currentItems.map((val, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{val?.fullname}</td>
                                <td>{val?.org_name}</td>
                                <td>{val?.email}</td>

                                <td>
                                  {moment(val?.createdAt).format("DD/MM/YYYY")}
                                </td>
                                <td>
                                  <div className="active px-3 py-2 rounded-pill text-white gray text-center">
                                    Inactive
                                  </div>
                                </td>
                                {/* <td>< NavLink to={`${AdminRoute?.Common?.EmployeesDetails}/${val?._id}`}><button className='details bg-white px-3 py-2 mx-2 rounded-pill border border-dark'>View Details</button></NavLink></td> */}
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="d-flex justify-content-end">
                      <div>
                        <Pagination>
                          <Pagination.First
                            onClick={() => {
                              setCurrentPageinactive(1);
                            }}
                          />
                          <Pagination.Prev
                            onClick={() => {
                              setCurrentPageinactive(
                                currentPageinactive > 1
                                  ? currentPageinactive - 1
                                  : 1
                              );
                            }}
                          />

                          {Array.from({ length: totalPagesinactive }).map(
                            (_, index) => (
                              <Pagination.Item
                                key={index}
                                active={index + 1 === currentPageinactive}
                                onClick={() =>
                                  handlePageChangeinactive(index + 1)
                                }
                              >
                                {index + 1}
                              </Pagination.Item>
                            )
                          )}
                          <Pagination.Next
                            onClick={() => {
                              setCurrentPageinactive(currentPageinactive + 1);
                            }}
                          />
                          <Pagination.Last
                            onClick={() => {
                              setCurrentPageinactive(totalPagesinactive);
                            }}
                          />
                        </Pagination>
                        <p className="mx-2">
                          Showing {currentPageinactive} of {totalPagesinactive}{" "}
                        </p>
                      </div>
                    </div> */}
                  </Tab>
                </Tabs>
                <div className="d-flex justify-content-end">
                  <div>
                    <Pagination>
                      <Pagination.First
                        onClick={() => {
                          setCurrentPage(1);
                        }}
                      />
                      <Pagination.Prev
                        onClick={() => {
                          setCurrentPage(currentPage > 1 ? currentPage - 1 : 1);
                        }}
                      />
                      {Array.from({ length: totalPages }).map((_, index) => (
                        <Pagination.Item
                          key={index}
                          active={index + 1 === currentPage}
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next
                        onClick={() => {
                          setCurrentPage(currentPage + 1);
                        }}
                      />
                      <Pagination.Last
                        onClick={() => {
                          setCurrentPage(totalPages);
                        }}
                      />
                    </Pagination>
                    <p className="mx-2">
                      Showing {currentPage} of {totalPages}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Employees;
