// App.js
import React, { useState, useEffect } from "react";
import Sidebar from "../Common/Sidebar";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import Button from "react-bootstrap/Button";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import DefaultUserImg from "../../Assets/Images/icons/Icon awesome-user-circle.png";
import API from "../../Api/Api";
import moment from "moment";

const OrganizationDetails = () => {
  const navigate = useNavigate();
  const [OrgDetails, setorgDetails] = useState([]);
  const [LoadingOrgDetails, setLoadingOrgDetails] = useState(false);
  const param = useParams();

  const GetOrgDetails = () => {
    setLoadingOrgDetails(true);

    // console.log(param?.id);
    try {
      API.Common.OrgDetails({
        data: { _id: param?.id },
      }).then((response) => {
        if (response?.data?.status === 1) {
          setorgDetails(response?.data?.data);
          setLoadingOrgDetails(false);
        }
      });
    } catch (error) {
      console.log(error);
      setLoadingOrgDetails(false);
    }
  };

  useEffect(() => {
    GetOrgDetails();
  }, []);

  //approve organization

  const GetApproveUser = (e) => {
    // setLoadingDetails(true)
    // console.log(param?.id);
    try {
      API.Common.IsApproveallorg({
        data: {
          _id: param?.id,
          is_authorized: 1,
          role: "Orgadmin",
        },
      }).then((response) => {
        if (response?.data?.status === 1) {
          navigate(`../${AdminRoute?.Common?.Organizations}`);
        }
      });
    } catch (error) {
      console.log(error);
      // setLoadingDetails(false)
    }
  };

  //jsx
  return (
    <div className="d-flex h-dashboard profile">
      <Sidebar />
      <div className="toggleIcon">
        <div className="container-fluid">
          <div className="nav-wrap">
            <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
              <h2 className="heading  mt-1 mb-1">
                <i
                  className="fa fa-chevron-left cursor_pointer pe-2"
                  aria-hidden="true"
                  onClick={() => navigate(-1)}
                ></i>{" "}
                Organization Details
              </h2>
              <Header />
            </nav>
          </div>
          {LoadingOrgDetails ? (
            <figure>
              <div className="dot white"></div>
              <div className="dot"></div>
              {/* <div className="dot"></div> */}
              {/* <div className="dot"></div>
                                                            <div className="dot"></div> */}
            </figure>
          ) : (
            <div className="details-wrap">
              <div className="row">
                <div className="col-md-12 b-0">
                  <div className="card-body payment-card-body p-0 profile-wrap">
                    <div className="row">
                      <div className="col-12">
                        <div className="profile-header-box">
                          <div className="col px-0 employee-dropdown setting-dropdown"></div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="profile-image-box ms-4 d-flex justify-content-center align-items-center">
                          <img
                            src={
                              OrgDetails?.image
                                ? OrgDetails?.image
                                : DefaultUserImg
                            }
                          ></img>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3 p-3">
                      <div className="col-lg-6">
                        <div className="col-12">
                          <h6 className="mb-2">Personal Details</h6>
                          <hr />
                        </div>
                        <form>
                          <div className="row">
                            <div className="col-12">
                              <div className="row">
                                <div className="col-12">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="Full Name"
                                      className="form-label"
                                    >
                                      Full Name
                                    </label>
                                    <input
                                      type="text"
                                      name="Name"
                                      defaultValue={OrgDetails?.fullname}
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="Email"
                                      className="form-label"
                                    >
                                      Email
                                    </label>
                                    <input
                                      type="email"
                                      name="Email"
                                      defaultValue={OrgDetails?.email}
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="Contact No"
                                      className="form-label"
                                    >
                                      Contact No.
                                    </label>
                                    <input
                                      type="tel"
                                      id="myNumberInput"
                                      name="PhoneNumber"
                                      defaultValue={OrgDetails?.phone}
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label htmlFor="Age" className="form-label">
                                      Date Of Birth
                                    </label>
                                    <input
                                      name="Age"
                                      type="tel"
                                      id="myNumberInput"
                                      maxLength={3}
                                      Value={moment(OrgDetails?.dob).format(
                                        "DD/MM/YYYY"
                                      )}
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>

                                <div className="col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="Gender"
                                      className="form-label"
                                    >
                                      Gender
                                    </label>
                                    <input
                                      name="Age"
                                      type="tel"
                                      id="myNumberInput"
                                      defaultValue={OrgDetails?.gender}
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>

                      <div className="col-lg-6">
                        <div className="col-12">
                          <h6 className="mb-2">Organization Details</h6>
                          <hr />
                        </div>
                        <form>
                          <div className="row">
                            <div className="col-12">
                              <div className="row">
                                <div className="col-12">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="Full Name"
                                      className="form-label"
                                    >
                                      Company Name
                                    </label>
                                    <input
                                      type="text"
                                      name="Companyname"
                                      placeholder="Enter your Company Name"
                                      defaultValue={OrgDetails?.org_name}
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="Email"
                                      className="form-label"
                                    >
                                      Company Address
                                    </label>
                                    <input
                                      type="text"
                                      name="Companyaddress"
                                      placeholder="Enter your Company Address"
                                      defaultValue={OrgDetails?.Companyaddress}
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="Contact No"
                                      className="form-label"
                                    >
                                      CIN NO.
                                    </label>
                                    <input
                                      type="text"
                                      name="CNnumber"
                                      defaultValue={OrgDetails?.CNnumber}
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label htmlFor="Age" className="form-label">
                                      GST Number
                                    </label>
                                    <input
                                      type="text"
                                      name="GSTnumber"
                                      defaultValue={OrgDetails?.GSTnumber}
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="Gender"
                                      className="form-label"
                                    >
                                      Email
                                    </label>
                                    <input
                                      type="email"
                                      placeholder="Enter E-Mail ID"
                                      name="email"
                                      defaultValue={OrgDetails?.Companyemail}
                                    />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="Years of experience"
                                      className="form-label"
                                    >
                                      Sector
                                    </label>
                                    <input
                                      type="email"
                                      placeholder="Enter E-Mail ID"
                                      name="email"
                                      defaultValue={OrgDetails?.Sector}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {OrgDetails?.is_authorized === 0 ? (
                              <div className="col-12">
                                <Button
                                  type="submit"
                                  className="create-new_notes button_common notes-btn transparent-btn mt-2 mt-0"
                                  onClick={(e) => GetApproveUser(e)}
                                >
                                  {" "}
                                  Profile Approve{" "}
                                </Button>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default OrganizationDetails;
