import React, { useState, useEffect } from 'react';
import Sidebar from '../Common/Sidebar';
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import AdminRoute from '../../Route/RouteDetails';
import API from '../../Api/Api';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';


const ResearchTeam = () => {
    const navigate = useNavigate();
    const [sumarySessionList, setSumarySessionList] = useState([]);
    const [upcomingSessionList, setUpcomingSessionList] = useState([]);
    const [loadSumaryList, setLoadSumaryList] = useState(false);
    const [loadUpcomingList, setLoadUpcomingList] = useState(false);


    useEffect(() => {
        getSumarySessionList()
        getUpcomingSessionList()
    }, [])

    // get summary list
    const getSumarySessionList = () => {
        setLoadSumaryList(true)
        try {
            API.Session.sumarySessionList().then((response) => {
                if (response.status === 200) {
                    setSumarySessionList(response?.data?.data_all);
                    setLoadSumaryList(false)
                }
            });
        } catch (error) {
            console.log(error);
            setLoadSumaryList(false)
        }
    }

    const getUpcomingSessionList = () => {
        setLoadUpcomingList(true)
        try {
            API.Session.upcomingSessionList().then((response) => {
                if (response.status === 200) {
                    setUpcomingSessionList(response?.data?.data_all);
                    setLoadUpcomingList(false)
                }
            });
        } catch (error) {
            console.log(error);
            setLoadUpcomingList(false)
        }
    }

    const handleEmployeeDetails = (val) => {
        navigate(`../${AdminRoute?.Common?.EmployeeDetails}`);
    }

    return (
        <div className="d-flex h-dashboard">
            <Sidebar />
            <div className='toggleIcon'>
                <div className="container-fluid">
                    <div className="row list">
                        <div className="">
                            <nav className="navbar navbar-expand-lg header-navbar p-0 mx-0">
                                <div className='w-100 h-head'>
                                    <h2 className='heading pt-0 mt-1 mb-1'>Research Team</h2>
                                </div>
                                <Header />
                            </nav>
                        </div>

                        <div className='w-50 mt-2 sort-filter position-absolute'>
                            <div className='col px-0 employee-dropdown d-flex justify-content-end align-items-center'>
                                <Dropdown className='float-end'>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <div className='icon-div me-0'><i className="fa fa-filter"></i></div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        < NavLink to={AdminRoute.Common.Profile}>Employee</NavLink><br></br>
                                        < NavLink to={AdminRoute.Common.Payment}>Leader</NavLink><br></br>
                                    </Dropdown.Menu>
                                </Dropdown>
                                {/* <button type="submit" className="create-new_notes button_common notes-btn transparent-btn btn btn-primary mb-0 mt-0 ms-3"><a className="" href="#">Bulk Upload</a></button>
                                <button type="submit" className="create-new_notes notes-btn btn btn-primary mb-0 mt-0 ms-2 me-0 button_common approve-btn"><a className="" href="#">Add Member</a></button> */}

                            </div>
                        </div>

                        <div className="col-md-12 mb-2 list-item organization-details-v-height">
                            <div className="sessions-tabs mt-2">
                                <Tabs
                                    defaultActiveKey="all"
                                    id="uncontrolled-tab-example"
                                    className="mb-3 px-0"
                                >
                                    <Tab eventKey="all" title="All">
                                        <div className='organizations-list mt-4'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Member ID</th>
                                                        <th scope="col">Member Name</th>
                                                        <th scope="col">Contact No.</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Total Content Published</th>
                                                        <th scope="col"></th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>####</td>
                                                        <td>Anuradha Krishnan</td>
                                                        <td>+91 9123456789</td>
                                                        <td>anuradha.krishnan@gail.com</td>
                                                        <td>05</td>
                                                        <td><div className="active px-3 py-2 rounded-pill text-white green text-center">Active</div></td>
                                                        <td>< NavLink to={AdminRoute.Common.ResearchTeamDetails}><button className='details bg-white px-3 py-2 mx-2 rounded-pill border border-dark'>View Details</button></NavLink></td>
                                                    </tr>
                                                    <tr>
                                                        <td>####</td>
                                                        <td>Anuradha Krishnan</td>
                                                        <td>+91 9123456789</td>
                                                        <td>anuradha.krishnan@gail.com</td>
                                                        <td>05</td>
                                                        <td><div className="active px-3 py-2 rounded-pill text-white gray text-center">Inactive</div></td>
                                                        <td><button className='details bg-white px-3 py-2 mx-2 rounded-pill border border-dark'>View Details</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td>####</td>
                                                        <td>Anuradha Krishnan</td>
                                                        <td>+91 9123456789</td>
                                                        <td>anuradha.krishnan@gail.com</td>
                                                        <td>05</td>
                                                        <td><div className="active px-3 py-2 rounded-pill text-white green text-center">Active</div></td>
                                                        <td><button className='details bg-white px-3 py-2 mx-2 rounded-pill border border-dark'>View Details</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td>####</td>
                                                        <td>Anuradha Krishnan</td>
                                                        <td>+91 9123456789</td>
                                                        <td>anuradha.krishnan@gail.com</td>
                                                        <td>05</td>
                                                        <td><div className="active px-3 py-2 rounded-pill text-white gray text-center">Inactive</div></td>
                                                        <td><button className='details bg-white px-3 py-2 mx-2 rounded-pill border border-dark'>View Details</button></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Tab>

                                    <Tab eventKey="active" title="Active">

                                        <div className='organizations-list mt-4'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Member ID</th>
                                                        <th scope="col">Member Name</th>
                                                        <th scope="col">Contact No.</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Total Content Published</th>
                                                        <th scope="col"></th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>####</td>
                                                        <td>Anuradha Krishnan</td>
                                                        <td>+91 9123456789</td>
                                                        <td>anuradha.krishnan@gail.com</td>
                                                        <td>05</td>
                                                        <td><div className="active px-3 py-2 rounded-pill text-white green text-center">Active</div></td>
                                                        <td><button className='details bg-white px-3 py-2 mx-2 rounded-pill border border-dark'>View Details</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td>####</td>
                                                        <td>Anuradha Krishnan</td>
                                                        <td>+91 9123456789</td>
                                                        <td>anuradha.krishnan@gail.com</td>
                                                        <td>05</td>
                                                        <td><div className="active px-3 py-2 rounded-pill text-white green text-center">Active</div></td>
                                                        <td><button className='details bg-white px-3 py-2 mx-2 rounded-pill border border-dark'>View Details</button></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </Tab>

                                    <Tab eventKey="inactive" title="Inactive">
                                        <div className='organizations-list mt-4'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Member ID</th>
                                                        <th scope="col">Member Name</th>
                                                        <th scope="col">Contact No.</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Total Content Published</th>
                                                        <th scope="col"></th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>####</td>
                                                        <td>Anuradha Krishnan</td>
                                                        <td>+91 9123456789</td>
                                                        <td>anuradha.krishnan@gail.com</td>
                                                        <td>05</td>
                                                        <td><div className="active px-3 py-2 rounded-pill text-white gray text-center">Inactive</div></td>
                                                        <td><button className='details bg-white px-3 py-2 mx-2 rounded-pill border border-dark'>View Details</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td>####</td>
                                                        <td>Anuradha Krishnan</td>
                                                        <td>+91 9123456789</td>
                                                        <td>anuradha.krishnan@gail.com</td>
                                                        <td>05</td>
                                                        <td><div className="active px-3 py-2 rounded-pill text-white gray text-center">Inactive</div></td>
                                                        <td><button className='details bg-white px-3 py-2 mx-2 rounded-pill border border-dark'>View Details</button></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div >






    );
}

export default ResearchTeam;
