import BaseApi from "./BaseApi";

const API = {
  //Superadmin auth apis
  Auth: {
    LoginAPIEmail: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/login`,
        data: props.data,
        tokenRequired: false,
      });
    },
  },
  Common: {
    MyOrgList: (props) => {
      return BaseApi({
        method: "GET",
        url: `users/getorganisation?is_authorized=${
          props.key === undefined ? "" : props.key
        }`,
        tokenRequired: true,
      });
    },
    //welnesscoach details page organization listing
    MyOrgListforwellnesscoach: (props) => {
      return BaseApi({
        method: "GET",
        url: "/users/getorganisation?is_authorized=1",
        tokenRequired: true,
      });
    },

    CLientlisting: (props) => {
      return BaseApi({
        method: "GET",
        url: `users/theropist?key=Client&page=1&size=50&is_active=${props.key}`,
        tokenRequired: true,
      });
    },

    //list active inactive
    TherpistList_API: (props) => {
      return BaseApi({
        method: "GET",
        url: `/users/theropist?key=Therapist&page=1&size=50&is_authorized=${
          props.key === undefined ? "" : props.key
        }`,
        tokenRequired: true,
      });
    },

    WellnessCoachlisting: (props) => {
      return BaseApi({
        method: "GET",
        url: `users/theropist?key=Wellnessclient&page=1&size=50&is_authorized=${
          props.key === undefined ? "" : props.key
        }`,

        tokenRequired: true,
      });
    },

    //new ai call for list
    WellnessCoachlistingnew: (props) => {
      return BaseApi({
        method: "GET",
        url: `superadmin/coachList?role=Wellnessclient&is_authorized=${
          props.key === undefined ? "" : props.key
        }`,
        tokenRequired: true,
      });
    },

    Employeelisting: (props) => {
      return BaseApi({
        method: "GET",
        url: `users/theropist?key=Cmsemployee&page=${props.currentPage}&size=${
          props.itemsPerPage
        }&is_authorized=${props.key === undefined ? "" : props.key}&org_name=${
          props.org_name
        }`,
        tokenRequired: true,
      });
    },
    //all details pae api
    OrgDetails: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/orgProfile`,
        data: props.data,
        tokenRequired: true,
      });
    },

    TherapistDetails: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/viewProfile`,
        data: props.data,
        tokenRequired: true,
      });
    },

    empDetails: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/viewProfile`,
        data: props.data,
        tokenRequired: true,
      });
    },

    ClientDetails: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/viewProfile`,
        data: props.data,
        tokenRequired: true,
      });
    },

    WellnesscoachDetails: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/viewProfile`,
        data: props.data,
        tokenRequired: true,
      });
    },

    //assign welnesscoach
    WellnesscoachAssign: (props) => {
      return BaseApi({
        method: "POST",
        url: `superadmin/assignCoach`,
        data: props.data,
        tokenRequired: true,
      });
    },

    //approve therapist
    IsApproveall: (props) => {
      return BaseApi({
        method: "POST",
        url: `superadmin/approveTherapists`,
        data: props.data,
        tokenRequired: true,
      });
    },

    IsApproveallorg: (props) => {
      return BaseApi({
        method: "POST",
        url: `superadmin/approveOrg`,
        data: props.data,
        tokenRequired: true,
      });
    },
    //add therapist
    Register: (props) => {
      return BaseApi({
        method: "POST",
        url: `users/createadmin`,
        data: props.data,
        tokenRequired: false,
      });
    },

    //add therapist
    Playgroundlist: (props) => {
      return BaseApi({
        method: "GET",
        url: `info/infolist?key=${props.key}`,
        tokenRequired: true,
      });
    },

    DashboardCountapi: (props) => {
      return BaseApi({
        method: "GET",
        url: `superadmin/totalCount`,
        tokenRequired: true,
      });
    },

    getWellnesscoachListcount: (props) => {
      return BaseApi({
        method: "GET",
        url: `superadmin/assignedEmployee?role=Wellnessclient`,
        tokenRequired: true,
      });
    },

    //api call  for organization view employee details
    getOrgEmpDetails: (props) => {
      return BaseApi({
        method: "GET",
        url: `superadmin/viewEmployee?Companyname=${props.name}&isactive=true`,
        tokenRequired: true,
      });
    },

    getInactiveEmployeelist: (props) => {
      return BaseApi({
        method: "GET",
        url: `users/csvUploadedUsers?key=Cmsemployee&page=${props.currentPage}&size=${props.itemsPerPage}&isActive=1&org_name=${props.org_name}`,
        // url: `users/csvUploadedUsers?page=1&key=Cmsemployee&size=50&isActive=1`,
        tokenRequired: true,
      });
    },

    EmployeeListbycsvorgviewdetailsemployee: (props) => {
      return BaseApi({
        method: "GET",
        url: `users/csvUploadedUsers?page=1&key=Cmsemployee&size=50&Companyname=${props.name}&isActive=1`,
        tokenRequired: true,
      });
    },

    Filterapiemployee: (props) => {
      return BaseApi({
        method: "GET",
        url: `superadmin/filter?role=Cmsemployee&page=1&size=50&lead=${props.isLead}&org_name=${props.org_name}`,
        tokenRequired: true,
      });
    },
  },

  NotificationApis: {
    getAllnotificationApi: (props) => {
      return BaseApi({
        method: "GET",
        url: `/users/notification`,
        tokenRequired: true,
      });
    },
  },
};

export default API;
